import { RecursiveKeyOf } from 'models';
import { Partner } from 'models/extension-generated';

export const privateDataScreenFields: RecursiveKeyOf<Partner>[] = [
  'livingInGermany',
  'firstname',
  'lastname',
  'applicationInformation.relationshipToHolder',
  'applicationInformation.employer',
  'applicationInformation.employmentGroup',
  'applicationInformation.profession',
  'applicationInformation.trainingStart',
  'applicationInformation.trainingEnd',
  'applicationInformation.isUnderIncomeLimit',
  'applicationInformation.hasSideJob',
  'permanentResidence.street',
  'permanentResidence.houseNumber',
  'permanentResidence.postalCode',
  'permanentResidence.city',
  'foreignCountry',
  'taxNumber'
];

export const tariffsDataScreenFields: RecursiveKeyOf<Partner>[] = [
  'birthDate',
  'gender',
  'title',
  'applicationInformation.federalState',
  'applicationInformation.tariffInformation.selectedTariffs',
  'applicationInformation.tariffInformation',
  'applicationInformation.tariffInformation.needSituation',
  'applicationInformation.tariffInformation.hasClaimSince2012',
  'applicationInformation.tariffInformation.hasDailyHospitalAllowance',
  'applicationInformation.tariffInformation.hasDailyCareAllowance',
  'applicationInformation.tariffInformation.hasInternationalTravelInsurance',
  'applicationInformation.tariffInformation.hasDailySicknessAllowance',
  'applicationInformation.tariffInformation.workIncomeLast12Months',
  'applicationInformation.tariffInformation.nationality',
  'applicationInformation.tariffInformation.deliveryDate',
  'applicationInformation.tariffInformation.selfEmployedSince',
  'applicationInformation.tariffInformation.companyType',
  'applicationInformation.tariffInformation.monthlyCompanyProfit',
  'applicationInformation.tariffInformation.companyName',
  'applicationInformation.tariffInformation.employeesCount',
  'applicationInformation.tariffInformation.gmbhShareholder',
  'applicationInformation.tariffInformation.hasSalaryDuringIllness',
  'applicationInformation.tariffInformation.salaryDuringIllnessDuration',
  'applicationInformation.tariffInformation.isExpectingChild'
];

export const summaryDataScreenFields: RecursiveKeyOf<Partner>[] = [
  'applicationInformation.acceptsPromotionalPurposes',
  'applicationInformation.acceptsPromotionalPurposesPhone',
  'applicationInformation.acceptsPromotionalPurposesEmail'
];

export const healthDataScreenFields: RecursiveKeyOf<Partner>[] = [
  'applicationInformation.hasLegalObligationToNotify'
];

export const generalDataScreenFields: RecursiveKeyOf<Partner>[] = [
  'applicationInformation.hasInsolvency',
  'applicationInformation.hasUsedEmergencyTariff',
  'applicationInformation.hasInsuranceDept',
  'applicationInformation.insuranceNumberLKH',
  'applicationInformation.comprehensiveHealthTransferValue',
  'applicationInformation.referenceValuePPV',
  'applicationInformation.fundsFromGZ',
  'applicationInformation.hasHealthPreInsurance',
  'applicationInformation.hasCarePreInsurance',
  'applicationInformation.hasSupplementaryPreInsurance',
  'applicationInformation.hasSickPayPreInsurance'
];
