import moment from 'moment';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField, partnerField } from 'models';
import {
  Application,
  NeedSituationEnum,
  Partner,
  PersonRoleEnum
} from 'models/extension-generated';

import { FINAL_YEAR_OF_BRE_AVAILABILITY } from 'pages/PrivateHealthInsurance/constants';

type UseApplicationHelpers = {
  getPartnerById: (id: string) => Partner;
  getPartnersByRole: (role: PersonRoleEnum) => Array<Partner>;
  getSinglePartnerByRole: (role: PersonRoleEnum) => Partner;
  isHolderAlsoPayer: () => boolean;
  isHolderInsured: (partner: Partner) => boolean;
  formatPartnerName: (id: string) => string;
  getStepHeading: (id: string) => string;
  getConfig: (partnerId: string) => {
    isDownloadOfferAvailable: boolean;
    orderOptionsEnabled: boolean;
    showBonityQuestionsAndTransferValues: boolean;
    showBRE: boolean;
    isFinalYearForBre: boolean;
  };
};

export const useApplicationHelpers = (application?: Application): UseApplicationHelpers => {
  /**
   * Returns partner instance from the application
   * Throws an error if partner does not exist
   *
   * @param id unique partner ID
   * @returns partner
   */
  function getPartnerById(id: string): Partner {
    const partner = application?.partners?.find((p) => p.id === id);
    if (!partner) {
      throw new Error('Partner not found within an application!');
    }
    return partner;
  }

  /**
   * Returns partners list by a given role
   *
   * @param role identifier
   * @returns partners list
   */
  function getPartnersByRole(role: PersonRoleEnum): Array<Partner> {
    return application?.partners?.filter((p) => p.roles.includes(role)) || [];
  }

  /**
   * Returns a single partner by a given role or throws an error if not found
   *
   * @param role identifier
   * @returns partner with a given role
   */
  function getSinglePartnerByRole(role: PersonRoleEnum): Partner {
    const partner = getPartnersByRole(role)[0];
    if (!partner) {
      throw new Error(`Partner with role ${role} does not exist!`);
    }
    return partner;
  }

  /**
   * Checks of the insurance holder & payment contributor is the same person
   *
   * @returns boolean if both IDs are the same
   */
  function isHolderAlsoPayer(): boolean {
    const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
    const payer = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR);
    return holder.id === payer.id;
  }

  /**
   * Checks if the same person is both: insured & insurance holder
   */
  function isHolderInsured(partner: Partner): boolean {
    return (
      partner.roles.includes(PersonRoleEnum.INSURED_PERSON) &&
      partner.roles.includes(PersonRoleEnum.POLICY_HOLDER)
    );
  }

  /**
   * Formats partner name to "{lastName}, {firstName}" format
   */
  function formatPartnerName(id: string): string {
    const partner = getPartnerById(id);

    const name = `${partner.lastname}, ${partner.firstname}`;

    return name;
  }

  function getStepHeading(id: string) {
    if (!application) return 'Versicherte Person N/A';

    let order = 0;
    const insuredPartners = application.partners.filter((p) =>
      p.roles.includes(PersonRoleEnum.INSURED_PERSON)
    );

    insuredPartners.some((p, index) => {
      if (p.id === id) {
        order = index + 1;
        return true;
      }

      return false;
    });

    return `Versicherte Person ${order}`;
  }

  /**
   * Returns boolean if current screen is bedarf
   */
  function getConfig(partnerId: string) {
    const ensuredApplication = application || {};

    const insuranceStartKey = applicationField('applicationStart');
    const birthDateKey = partnerField(partnerId, 'birthDate');
    const salutationKey = partnerField(partnerId, 'salutation');
    const selectedTariffsKey = partnerField(
      partnerId,
      'applicationInformation.tariffInformation.selectedTariffs'
    );
    const needSituationKey = partnerField(
      partnerId,
      'applicationInformation.tariffInformation.needSituation'
    );

    const birthDate = formModelGet(ensuredApplication, birthDateKey);
    const salutation = formModelGet(ensuredApplication, salutationKey);
    const insuranceStart = formModelGet(ensuredApplication, insuranceStartKey);
    const tariffs = formModelGet(ensuredApplication, selectedTariffsKey) || [];
    const needSituation = formModelGet(ensuredApplication, needSituationKey);
    const startYear = moment(formModelGet(ensuredApplication, insuranceStartKey)).year();

    return {
      isDownloadOfferAvailable:
        !!birthDate && !!salutation && !!insuranceStart && tariffs.length > 0,
      orderOptionsEnabled: !!birthDate && !!salutation && !!insuranceStart,
      showBonityQuestionsAndTransferValues:
        needSituation !== NeedSituationEnum.ZVGKV && needSituation !== NeedSituationEnum.ZVGKV_U,
      /**
       * ============== Start BRE ===========
       * This section looses purpose in 2025
       */
      showBRE:
        NeedSituationEnum.ZVGKV !== needSituation && NeedSituationEnum.ZVGKV_U !== needSituation,
      isFinalYearForBre: startYear === FINAL_YEAR_OF_BRE_AVAILABILITY
      /**
       * ============== End BRE ===========
       */
    };
  }

  return {
    getPartnerById,
    getPartnersByRole,
    getSinglePartnerByRole,
    isHolderAlsoPayer,
    isHolderInsured,
    formatPartnerName,
    getStepHeading,
    getConfig
  };
};
