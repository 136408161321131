import { ChangeEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Breakpoint } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField } from 'models';

import DownloadStage from './components/DownloadStage/DownloadStage';
import SelectionStage from './components/SelectionStage/SelectionStage';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { useApplicaitionPersistance } from 'pages/PrivateHealthInsurance/hooks/useApplicationPersistance';
import { downloadOfferDocument } from 'utilities/fetchContract';

type DownloadOfferDialogProps = {
  isDownloadOfferDialogOpen: boolean;
  setIsDownloadOfferDialogOpen: (value: boolean) => void;
};

export enum DownloadOfferOption {
  Full = 'Full',
  Partial = 'Partial'
}

enum DialogStage {
  Selection,
  Downloading
}

export const DownloadOfferDialog = ({
  isDownloadOfferDialogOpen,
  setIsDownloadOfferDialogOpen
}: DownloadOfferDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state, reducer } = useHealthInsuranceContext();
  const { save } = useApplicaitionPersistance();
  const navigate = useNavigate();
  const abortController = useRef<AbortController | null>(null);
  const [downloadOfferOption, setDownloadOfferOption] = useState<DownloadOfferOption>(
    DownloadOfferOption.Full
  );
  const [dialogStage, setDialogStage] = useState<DialogStage>(DialogStage.Selection);

  const handleDownloadOfferOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDownloadOfferOption(event.target.value as DownloadOfferOption);
  };

  const handleCloseDialog: DialogProps['onClose'] = (event, reasone) => {
    if (
      reasone &&
      (reasone === 'backdropClick' || reasone === 'escapeKeyDown') &&
      dialogStage === DialogStage.Downloading
    ) {
      return;
    }
    setIsDownloadOfferDialogOpen(false);
  };

  const resolveDialogWidth = (): Breakpoint => {
    if (dialogStage === DialogStage.Downloading) {
      return 'sm';
    }
    return 'md';
  };

  const downloadOffer = async () => {
    const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
    if (!applicationStart) {
      toast.warning(t<string>('common:toastMessages.missingApplicationStartDate'), {
        delay: 500
      });
      return;
    }
    const newController = new AbortController();
    abortController.current = newController;
    const { signal } = abortController.current;
    try {
      setDialogStage(DialogStage.Downloading);
      const cloned = cloneDeep(state.model);
      const { id } = await save(cloned);

      reducer.updateValue({ key: 'id', value: id });
      navigate(`${WizardRoutesEnum.Home}?id=${id}`);

      const shouldDownloadAccomapnyDocs =
        downloadOfferOption === DownloadOfferOption.Full ? true : false;
      await downloadOfferDocument({
        requestBody: { id, ...cloned },
        downloadAccompanyDocs: shouldDownloadAccomapnyDocs,
        signal: signal
      });
    } catch (error) {
      /* empty */
    } finally {
      setIsDownloadOfferDialogOpen(false);
    }
  };

  const handleCancalationOfDownload = () => {
    if (abortController.current instanceof AbortController) {
      abortController.current.abort();
      setIsDownloadOfferDialogOpen(false);
    }
  };

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth={resolveDialogWidth()}
      open={isDownloadOfferDialogOpen}
      onClose={handleCloseDialog}
    >
      {dialogStage === DialogStage.Selection && (
        <SelectionStage
          downloadOfferOption={downloadOfferOption}
          handleDownloadOfferOptionChange={handleDownloadOfferOptionChange}
          handleCloseDownloadOfferDialog={() => setIsDownloadOfferDialogOpen(false)}
          downloadOffer={downloadOffer}
        />
      )}
      {dialogStage === DialogStage.Downloading && (
        <DownloadStage
          downloadOfferOption={downloadOfferOption}
          handleCancalationOfDownload={handleCancalationOfDownload}
        />
      )}
    </Dialog>
  );
};
