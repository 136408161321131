import { PartnerIdProp } from '../../../../../../../models';

import { CarePreInsurance } from '../CarePreInsurance';
import { GeneralValues } from '../GeneralValues';
import { PrivateHealthPreInsurance } from '../PrivateHealthPreInsurance';
import { SickDayPreInsurance } from '../SickPayPreInsurance';
import { SupplementaryPreInsurance } from '../SupplementaryPreInsurance';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

type GeneralQuestionsProps = PartnerIdProp;

export const GeneralQuestions = ({ partnerId }: GeneralQuestionsProps) => {
  const { state } = useHealthInsuranceContext();
  const { getConfig } = useApplicationHelpers(state.model);

  const config = getConfig(partnerId);

  return (
    <>
      <PrivateHealthPreInsurance partnerId={partnerId} />
      <CarePreInsurance partnerId={partnerId} />
      <SickDayPreInsurance partnerId={partnerId} />
      <SupplementaryPreInsurance partnerId={partnerId} />
      {config.showBonityQuestionsAndTransferValues && <GeneralValues partnerId={partnerId} />}
    </>
  );
};
