import { CellValueChangedEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import { formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { NeedSituationEnum, Tariff } from 'models/extension-generated';

import { OfferTariffTable } from 'components/OfferTariffTable';
import { TariffRow } from 'components/OfferTariffTable/OfferTariffTable';
import { useApplicationCalculate } from 'hooks/useApplicationCalculate';
import { UNDELETEABLE_TARIFF_IDS } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';

type TariffProps = {
  allowEditing?: boolean;
  tariffs: Array<Tariff>;
  onTariffEdit?: (event: CellValueChangedEvent<TariffRow>) => void;
  partnerId: string | undefined;
};

export const Tariffs = ({
  allowEditing = true,
  tariffs,
  onTariffEdit,
  partnerId = ''
}: TariffProps) => {
  const { t } = useTranslation('contractsEnums');
  const { state, reducer } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { data } = useApplicationCalculate(state.model, questions, false);

  const { removeTariff, updateTariff } = reducer;

  const partnerCalculation = data?.partners.find((partner) => {
    return partner.partnerId === partnerId;
  });

  const handleCellEdit = (event: CellValueChangedEvent<TariffRow>) => {
    allowEditing && onTariffEdit?.(event);
  };

  const needSituationPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.needSituation'
  );
  const needSituation: NeedSituationEnum = formModelGet(state.model, needSituationPath);

  const addedRiskAmountTotal = tariffs.reduce((prev, next) => {
    const addedRiskAmount = next.addedRiskAmount || 0;
    return prev + addedRiskAmount;
  }, 0);

  return (
    <div className="grid-res my-[0px]">
      <div>
        <OfferTariffTable
          summaryRow={{
            id: 'sampleId',
            product: t('monthlyPayment', {
              ns: 'wizardOffer'
            }),
            addedRiskAmount: addedRiskAmountTotal,
            price: { total: partnerCalculation?.calculationMonthly?.amount }
          }}
          allowEditing={allowEditing}
          onTariffEdit={handleCellEdit}
          tariffs={tariffs.map((tariff) => {
            const {
              maxRiskSurcharge,
              section,
              description,
              highMoneyAmount,
              addedRiskAmount,
              hasProspectiveEntitlement,
              id,
              isOptional
            } = tariff;

            const traingTariffConstrain =
              needSituation === NeedSituationEnum.SU_S && UNDELETEABLE_TARIFF_IDS.includes(id);
            const showEntitlement =
              !['AKV', 'OPT_401'].includes(id) &&
              !traingTariffConstrain &&
              needSituation !== NeedSituationEnum.ZVGKV;
            const showDeleteTariff = isOptional;

            const tariffPrice = partnerCalculation?.tariffCalculations?.find((t) => {
              return t.tariffId === id;
            })?.calculationMonthly;
            const removeTariffClick = () => {
              if (partnerId) {
                removeTariff({
                  partnerId,
                  tariff: tariff
                });
              }
            };
            const changeProspectiveEntitlement = () => {
              if (partnerId && id) {
                updateTariff({
                  partnerId,
                  tariffId: id,
                  key: 'hasProspectiveEntitlement',
                  value: !hasProspectiveEntitlement
                });
              }
            };

            return {
              id: id,
              product: t(`TariffSectionEnum.${section}`),
              tariffLabel: { tariffLabel: id, tooltip: description || '' },
              entitlement: hasProspectiveEntitlement,
              highMoneyAmount: highMoneyAmount,
              addedRiskAmount: addedRiskAmount,
              price: {
                total: tariffPrice?.amount,
                maxRisSurcharge: maxRiskSurcharge,
                statutorySurcharge: tariffPrice?.statutorySurcharge
              },
              actionComponent: {
                tariffId: id,
                isEditable: allowEditing,
                hasEntitlement: hasProspectiveEntitlement || false,
                showEntitlement: showEntitlement,
                showDeleteTariff: showDeleteTariff,
                onRemoveTariffClick: removeTariffClick,
                onProspectiveEntitlementClick: changeProspectiveEntitlement
              }
            };
          })}
        />
      </div>
    </div>
  );
};
