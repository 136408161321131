import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { partnerField } from '../../../../../../../models';
import { TariffSectionEnum } from '../../../../../../../models/extension-generated';
import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { Form, formModelGet } from 'lkh-portal-ui-library';

import i18n from 'services/translation';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { OptionalSection } from '../../../components/OptionalSection';
import { getOptions } from '../../../components/OptionalSection/helpers';
import { SectionTitle } from '../../../components/SectionTitle';
import { TariffSteps } from '../../../components/TariffSteps';
import { RequiresTariffsProvider } from '../../../contexts/TariffsContext';
import { DetailsLink } from '../DetailsLink/DetailsLink';
import { DAILY_CARE_ALLOWANCE_DOCUMENT } from 'pages/PrivateHealthInsurance/constants';

const { DatePicker, YesNo } = Form.Components;

export const CareAllowanceTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.PFLEGEGELD;
  const { t } = useTranslation('wizardTariffs', { keyPrefix: `sections.${SECTION}` });
  const { partnerId, state, reducer } = useHealthInsuranceContext();
  const { updateValue } = reducer;

  const isExpectingChildKey = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.isExpectingChild'
  );
  const isExpectingChild = formModelGet(state.model, isExpectingChildKey);

  const handleDailyCareAllowance = (val: boolean) => {
    if (!val) {
      updateValue({
        key: isExpectingChildKey,
        value: undefined
      });
      updateValue({
        key: partnerField(partnerId, `applicationInformation.tariffInformation.deliveryDate`),
        value: undefined
      });
    }
  };

  const options = getOptions(t('noAnswer'));
  const hasDailyCareAllowancePath =
    'applicationInformation.tariffInformation.hasDailyCareAllowance';
  const value = formModelGet(state.model, partnerField(partnerId, hasDailyCareAllowancePath));
  const selected = options.find((opt) => opt.value === value);

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <OptionalSection
        componentKey={hasDailyCareAllowancePath}
        selected={selected}
        title={
          <SectionTitle
            title={
              <div className="flex gap-[8px] items-center content-center">
                <Typography variant="bodyLGBold">{t('question')}</Typography>
                <DetailsLink
                  isGetByReferenceId
                  documentId={DAILY_CARE_ALLOWANCE_DOCUMENT}
                  applicationStart={state.model.applicationStart}
                />
              </div>
            }
          />
        }
        noAnswerText={t('noAnswer')}
        onChange={handleDailyCareAllowance}
      >
        <div className="mt-[32px]">
          <TariffSteps
            title={t('stepperHeader')}
            stepLabel={i18n.t('utils.perDay', {
              ns: 'wizardTariffs'
            })}
          />
          <div className="mt-m">
            <YesNo
              componentKey={partnerField(
                partnerId,
                'applicationInformation.tariffInformation.isExpectingChild'
              )}
              question={t('isExpectingChild')}
            />
          </div>
          {isExpectingChild && (
            <>
              <div className="grid-res ml-[0px]">
                <div>
                  <Typography variant="bodyLGRegular" className="mb-[8px]">
                    {t('deliveryDate.question')}
                  </Typography>
                  <Typography variant="bodySMRegular" className="text-text-60">
                    {t('deliveryDate.description')}
                  </Typography>
                </div>
              </div>
              <div className="grid-res-2 ml-[0px]">
                <div>
                  <DatePicker
                    componentKey={partnerField(
                      partnerId,
                      `applicationInformation.tariffInformation.deliveryDate`
                    )}
                    label={t('deliveryDate.label')}
                    placeholder={t('deliveryDate.placeholder')}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </OptionalSection>
    </RequiresTariffsProvider>
  );
};
