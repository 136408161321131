import { AgeWarning, Form, FormState, PartnerForm } from 'lkh-portal-ui-library';
import { Application } from 'models/extension-generated';

import { WizardSlot } from '../../../../layout/wizard/slots';
import { PrivateDataPictogram } from '../../components/pictograms';
import { StepHeading } from '../../components/StepHeading';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { downloadAgeOver60Form } from 'utilities/fetchDocument';

const renderAgeThresholdWarning = (state: FormState<Application>) => (
  <AgeWarning downloadData={() => downloadAgeOver60Form(state.model.applicationStart || '')} />
);

export const PrivateDataStep = () => {
  const { state, dispatch, partnerId } = useHealthInsuranceContext();
  const { getPartnerById, getStepHeading, formatPartnerName } = useApplicationHelpers(state.model);
  const partner = getPartnerById(partnerId);

  const name =
    partner.firstname || partner.lastname
      ? formatPartnerName(partnerId)
      : getStepHeading(partner.id || '');
  return (
    <>
      <StepHeading icon={<PrivateDataPictogram />} title={name} showProgress />
      <div className="layout-res mt-[16px]">
        <WizardSlot.Main>
          <Form state={state} dispatch={dispatch}>
            <PartnerForm
              config={{
                useConfigContext: useConfigContext,
                useInsuranceContext: useHealthInsuranceContext,
                configuration: {
                  birthdate: {
                    disabled: true
                  },
                  gender: {
                    disabled: true
                  }
                }
              }}
              AgeThresholdWarningComponent={() => renderAgeThresholdWarning(state)}
              partnerId={partnerId}
              hasPersonalData={{
                hasAgeWarning: true
              }}
              hasHolderRelation
              hasEmployment
              hasTaxNumber
              hasAddress
              hasForeignAddress
            />
          </Form>
        </WizardSlot.Main>
      </div>
    </>
  );
};
