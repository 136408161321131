import { useTranslation } from 'react-i18next';

import EuroIcon from '@mui/icons-material/Euro';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import Typography from '@mui/material/Typography';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import {
  GmbhShareholderEnum,
  NeedSituationEnum,
  WorkingDoctorRelationshipEnum
} from 'models/extension-generated';

import { useProvidedTariffs } from '../../../../contexts/TariffsContext';

const { Input, YesNo, DatePicker } = Form.Components;

export const SickBenefitsTariffDetails = () => {
  const { section, partnerId, state, reducer } = useProvidedTariffs();
  const { t } = useTranslation('wizardTariffs', { keyPrefix: `sections.${section}` });
  const {
    hasError: hasGmbhShareHolderError,
    message,
    value
  } = Form.hooks.useFormComponent(
    partnerField(partnerId, 'applicationInformation.tariffInformation.gmbhShareholder')
  );
  const { hasError: hasMonthlyCompanyProfitError, value: monthlyCompanyProfit } =
    Form.hooks.useFormComponent(
      partnerField(partnerId, 'applicationInformation.tariffInformation.monthlyCompanyProfit')
    );
  const needSituation = formModelGet(
    state.model,
    partnerField(partnerId, 'applicationInformation.tariffInformation.needSituation')
  );
  const workingDoctorRelationship = formModelGet(
    state.model,
    partnerField(partnerId, 'applicationInformation.tariffInformation.workingDoctorRelationship')
  );

  const isSelfEmployed = workingDoctorRelationship === WorkingDoctorRelationshipEnum.SELF_EMPLOYED;
  const shouldDisplayQuestions =
    needSituation === NeedSituationEnum.SU_P ||
    needSituation === NeedSituationEnum.SU_P_U ||
    (needSituation === NeedSituationEnum.HMU_P && isSelfEmployed) ||
    (needSituation === NeedSituationEnum.HMU_P_U && isSelfEmployed);
  if (!shouldDisplayQuestions) {
    return <></>;
  }

  const hasSalaryDuringIllnessKey = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.hasSalaryDuringIllness'
  );
  const hasSalaryDuringIllness = formModelGet(state.model, hasSalaryDuringIllnessKey);

  return (
    <Form.Section>
      <div className="my-[24px] text-center s:text-left">
        <Typography variant="bodyLGBold">{t('independence.title')}</Typography>
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.selfEmployedSince.question')}
        </Typography>
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.companyName.question')}
        </Typography>
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <DatePicker
          label={t('independence.selfEmployedSince.label')}
          componentKey={partnerField(
            partnerId,
            'applicationInformation.tariffInformation.selfEmployedSince'
          )}
        />
        <Input
          componentKey={partnerField(
            partnerId,
            'applicationInformation.tariffInformation.companyName'
          )}
          label={t('independence.companyName.label')}
        />
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.companyType.question')}
        </Typography>
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.employeesCount.question')}
        </Typography>
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <Input
          componentKey={partnerField(
            partnerId,
            'applicationInformation.tariffInformation.companyType'
          )}
          label={t('independence.companyType.label')}
        />
        <Input
          componentKey={partnerField(
            partnerId,
            'applicationInformation.tariffInformation.employeesCount'
          )}
          label={t('independence.employeesCount.label')}
          placeholder="0"
          type="number"
        />
      </div>

      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.hasSalaryDuringIllness.question')}
        </Typography>
      </div>
      <div className="grid-res-2 mx-[0px]">
        <YesNo
          componentKey={hasSalaryDuringIllnessKey}
          onChange={(val) => {
            if (!val.value) {
              reducer.updateValue({
                key: partnerField(
                  partnerId,
                  'applicationInformation.tariffInformation.salaryDuringIllnessDuration'
                ),
                value: ''
              });
            }
          }}
        />
        <Input
          type="number"
          label={t('independence.salaryDuringIllnessDuration.label')}
          disabled={!hasSalaryDuringIllness}
          componentKey={partnerField(
            partnerId,
            'applicationInformation.tariffInformation.salaryDuringIllnessDuration'
          )}
        />
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.gmbhShareholder.question')}
        </Typography>
        <Typography variant="bodyLGRegular" className="text-text-80">
          {t('independence.monthlyCompanyProfit.question')}
        </Typography>
      </div>
      <div className="grid-res-2 mx-[0px] mt-[16px]">
        <FormControl error={hasGmbhShareHolderError}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row
            value={value}
            onChange={(_event, value) => {
              reducer.updateValue({
                key: partnerField(
                  partnerId,
                  'applicationInformation.tariffInformation.gmbhShareholder'
                ),
                value: value
              });
            }}
          >
            <FormControlLabel
              classes={{
                label: 'text-sm'
              }}
              value={GmbhShareholderEnum.NOT}
              control={<Radio className={hasGmbhShareHolderError ? 'text-danger-60' : ''} />}
              label={t('independence.gmbhShareholder.options.no')}
            />
            <FormControlLabel
              classes={{
                label: 'text-sm'
              }}
              value={GmbhShareholderEnum.YES_DOMINANT}
              control={<Radio className={hasGmbhShareHolderError ? 'text-danger-60' : ''} />}
              label={t('independence.gmbhShareholder.options.yesDominant')}
            />
            <FormControlLabel
              classes={{
                label: 'text-sm'
              }}
              value={GmbhShareholderEnum.YES_NO_DOMINANT}
              control={<Radio className={hasGmbhShareHolderError ? 'text-danger-60' : ''} />}
              label={t('independence.gmbhShareholder.options.yesNonDominant')}
            />
          </RadioGroup>
          {hasGmbhShareHolderError && (
            <FormHelperText>
              <Typography variant="bodySMRegular" className="text-danger-60">
                {message}
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          type="number"
          label={t('independence.monthlyCompanyProfit.label')}
          error={hasMonthlyCompanyProfitError}
          value={monthlyCompanyProfit}
          onChange={(event) => {
            reducer.updateValue({
              key: partnerField(
                partnerId,
                'applicationInformation.tariffInformation.monthlyCompanyProfit'
              ),
              value: event.target.value
            });
          }}
          helperText={
            hasMonthlyCompanyProfitError ? (
              <Typography variant="bodySMRegular" className="text-danger-60">
                {message}
              </Typography>
            ) : (
              <></>
            )
          }
          InputProps={{
            endAdornment: <EuroIcon fontSize="small" className="text-text-40" />
          }}
        />
      </div>
    </Form.Section>
  );
};
