import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { WizardRoutesEnum } from '../../constants';

export type WizardNavigationContextType = {
  /**
   * Current section (from the top navigation)
   */
  currentSection: number;

  /**
   * Complete sections (from the top navigation)
   */
  completeSections: Array<number>;

  /**
   * Currently active screen route
   */
  currentScreen: WizardRoutesEnum;

  /**
   * Navigates one screen back
   */
  back: (id?: string) => void;

  /**
   * Advance one screen further
   */
  next: (id?: string) => void;

  /**
   * Jump to any screen
   */
  goTo: (screen: WizardRoutesEnum, id?: string) => void;

  /**
   * Returns list of already completed sections (topbar)
   */
  resolveCompleteScreens: () => Array<WizardRoutesEnum>;

  /**
   * Order of the screens
   */
  ScreenOrder: Array<WizardRoutesEnum>;
};

export const WizardNavigationContext = createContext<WizardNavigationContextType | null>(null);

export const WizardNavigationContextProvider = ({ children }: PropsWithChildren) => {
  const ScreenOrder = [
    WizardRoutesEnum.Tariffs,
    WizardRoutesEnum.Offer,
    WizardRoutesEnum.GeneralQuestions,
    WizardRoutesEnum.HealthQuestions,
    WizardRoutesEnum.PrivateData,
    WizardRoutesEnum.InsuranceHolder,
    WizardRoutesEnum.Summary,
    WizardRoutesEnum.Legal
  ];

  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(WizardRoutesEnum.Tariffs);

  const currentSection = useMemo(() => {
    switch (currentScreen) {
      case WizardRoutesEnum.Summary:
      case WizardRoutesEnum.Legal:
        return 2;
      case WizardRoutesEnum.InsuranceHolder:
        return 1;
      default:
        return 0;
    }
  }, [currentScreen]);

  const completeSections = useMemo(() => {
    switch (currentScreen) {
      case WizardRoutesEnum.Summary:
      case WizardRoutesEnum.Legal:
        return [0, 1];
      case WizardRoutesEnum.InsuranceHolder:
        return [0];
      default:
        return [];
    }
  }, [currentScreen]);

  function goTo(screen: WizardRoutesEnum, id?: string): void {
    if (id) {
      navigate(`${WizardRoutesEnum.Home}?id=${id}`);
    }
    setCurrentScreen(screen);
  }

  function resolveCompleteScreens(): Array<WizardRoutesEnum> {
    const reversed = [...ScreenOrder].reverse();
    const index = reversed.indexOf(currentScreen);
    return reversed.slice(index);
  }

  function back(id?: string): void {
    const index = ScreenOrder.indexOf(currentScreen) - 1;
    goTo(ScreenOrder[index], id);
  }

  function next(id?: string): void {
    const index = ScreenOrder.indexOf(currentScreen) + 1;
    goTo(ScreenOrder[index], id);
  }

  const ctx = useMemo(() => {
    return {
      currentScreen,
      currentSection,
      completeSections,
      ScreenOrder,
      next,
      back,
      goTo,
      resolveCompleteScreens
    };
  }, [
    currentScreen,
    currentSection,
    completeSections,
    ScreenOrder,
    next,
    back,
    goTo,
    resolveCompleteScreens
  ]);

  return (
    <WizardNavigationContext.Provider value={ctx}>{children}</WizardNavigationContext.Provider>
  );
};

export const useWizardNavigation = (): WizardNavigationContextType => {
  const wizardNavigationContext = useContext(WizardNavigationContext);

  if (!wizardNavigationContext) {
    throw new Error(
      'To use "useWizardNavigation" some of the parent components must be in <WizardNavigationContextProvider>'
    );
  }

  return wizardNavigationContext;
};
