import { ReactNode } from 'react';

import { HealthQuestionDetail, PreInsurance } from './extension-generated';
import { ContributionRefund, PolicyRevision } from './portal-generated';

/**
 * Available document types
 */
export enum DocumentTypeEnum {
  Offer = 'Antrag',
  Contract = 'Vertrag'
}

/**
 * Data Wrapper for entire 360 view
 */
export type View360Data = {
  /**
   * Type of the document (antrag/vertrage/...)
   */
  documentType: DocumentTypeEnum;

  /**
   * Unique ID of a document
   */
  id: string;

  /**
   * Status of a contract/offer
   * (already formatted/translated if applicable)
   */
  status: ReactNode;

  /**
   * Signature status (Offers only)
   * (already formatted/translated if applicable)
   */
  signatureStatus?: SignatureStatus;

  /**
   * Available revisions (contract only)
   */
  revisions?: Array<PolicyRevision>;

  /**
   * Information about current revision (contract only)
   */
  currentRevision?: PolicyRevision;

  /**
   * Policy holder data wrapper
   */
  policyHolder: PolicyHolderDetailProps;

  /**
   * Payment contributor data wrapper
   */
  paymentContributor: PaymentContributorDetailProps;

  /**
   * Brief contract information data wrapper
   */
  contract: ContractSummaryProps;

  /**
   * List of insured persons
   *
   * Note: the list is used as-is and is not further manipulated/sorted
   */
  insuredPersons: Array<InsuredPersonDetailProps>;

  /**
   * Related documents data wrapper
   */
  documents: DocumentsProps;

  /**
   * [optional] Reminders (Mahnungen) data wrapper
   * If not present, reminders section is skipped
   */
  reminders?: ContractReminderProps;

  /**
   * Data about related bank accounts (contract only)
   */
  accounts?: AccountsProps;

  /**
   * Additional contract info (contract only)
   */
  additionalContractInfo?: AdditionalContractInfoProps;

  /**
   * Vermittler information (contract only)
   */
  agent?: AgentProps;

  /**
   * LKH Organization (contract only)
   */
  organization?: OrganizationProps;
};

/**
 * Every available prop for person
 */
export type PersonProps = {
  /**
   * Full name of the person
   * (already formatted)
   */
  name?: string;

  /**
   * First name only
   * (as is)
   */
  firstName?: string;

  /**
   * Last name only
   * (as is)
   */
  lastName?: string;

  /**
   * Birth date
   * (ISO compatible date format)
   */
  birthDate?: string;

  /**
   * Professional position (Berufliche Gruppe)
   * (already formatted)
   */
  professionalPosition?: string;

  /**
   * Salutation
   * (already formatted)
   */
  salutation?: string;

  /**
   * Academic title
   * (already formatted)
   */
  academicTitle?: string;

  /**
   * Marital (family) status
   * (already formatted)
   */
  maritalStatus?: string;

  /**
   * Nationality
   * (already formatted)
   */
  nationality?: string;

  /**
   * Current occupation (Jetzige berufliche Tätigkeit)
   * (already formatted)
   */
  currentOccupation?: string;

  /**
   * Current employer (Arbeitgeber, Betrieb, Ausbildungsstätte)
   * (already formatted)
   */
  employer?: string;

  /**
   * Optional training start date
   * (ISO compatible date format)
   */
  trainingStart?: string;

  /**
   * Optional training end date
   * (ISO compatible date format)
   */
  trainingEnd?: string;

  /**
   * Entire permanent address
   * (already formatted)
   */
  address?: string;

  /**
   * Whether is currently living in Germany
   */
  isLivingInGermany?: boolean;

  /**
   * Permanent residence street name only
   * (already formated if applicable)
   */
  street?: string;

  /**
   * Permanent residence house number only
   * (already formated if applicable)
   */
  houseNumber?: string | number;

  /**
   * Permanent residence postal code only
   * (already formated if applicable)
   */
  postalCode?: string;

  /**
   * Permanent residence city name only
   * (already formated if applicable)
   */
  city?: string;

  /**
   * Permanent residence country
   * (already formatted)
   */
  country?: string;

  /**
   * Whether person is living abroad in last 5 years
   */
  hasForeignResidence?: boolean;

  /**
   * Foreign residence (in last 5 years) country
   * (already formatted)
   */
  foreignCountry?: string;

  /**
   * Contact email address
   * (already formatted)
   */
  email?: string;

  /**
   * Contact phone number
   * (already formatted)
   */
  phone?: string;

  /**
   * Has children under 18 that the person is legal representative for?
   */
  hasLegalRepresentative?: boolean;

  /**
   * Is there sole custody for all children under 18?
   */
  acceptsSoleCustody?: boolean;

  /**
   * What marketing streams the partner accepts to be contacted by
   * (already formatted)
   */
  acceptedMarketingStreams?: string;

  /**
   * IBAN
   * (already formatted)
   */
  iban?: string;

  /**
   * Name of the bank (kreditinstitut)
   * (already formatted)
   */
  bankName?: string;

  /**
   * BIC number
   */
  bic?: string;

  /**
   * If the person gave the permission to direct payments by LKH
   */
  hasPaymentAuthorization?: boolean;

  /**
   * Tax identification number (Steueridentifikationsnummer)
   * (already formatted)
   */
  taxNumber?: string;

  /**
   * Einwilligung zur Kontaktaufnahme
   */
  hasConsentToContact?: boolean;

  /**
   * Einwilligung zum Vertrag
   * (ISO compatible date format)
   */
  consentToContactDate?: string;
};

/**
 * Data for a policy holder summary & details
 */
export type PolicyHolderDetailProps = PersonProps;

/**
 * Payment Contributor accordion details
 */
export type PaymentContributorDetailProps = PersonProps & {
  /**
   * Flag if the person is also policy holder
   */
  isPolicyHolder?: boolean;
};

/**
 * Insured person accordion details
 */
export type InsuredPersonDetailProps = PersonProps & {
  /**
   * Order of the person within the contract
   * (numeric value)
   */
  order: number;

  /**
   * Flag whether the person is also a policy holder
   * (optional, defaults to false)
   */
  isPolicyHolder?: boolean;

  /**
   * Monthly price of the insurance product for person
   * (numeric value, not formatted)
   */
  amount?: number;

  /**
   * Entire tariff table
   * Due to complexity, this is considered to be entire ReactNode instead of the component to take care of
   * (ReactNode)
   */
  tariffTable?: ReactNode;

  /**
   * Type of insurance / Versicherungstyp
   * (already formatted)
   */
  insuranceType?: string;

  /**
   * Bedarfsituation
   * (already formatted)
   */
  needSituation?: string;

  /**
   * Insurance start
   * (ISO compatible date format)
   */
  insuranceStart?: string;

  /**
   * Beihilfeträger
   * (already formatted)
   */
  federalState?: string;

  /**
   * Behilfe-Anspruch
   * (already formatted)
   */
  aidClaim?: string;

  /**
   * Bestand seit dem 31.12.2012 durchgehend ein Anspruch auf Beihilfe?
   */
  hadInsuranceSince2012?: boolean;

  /**
   * Refunds per year (if available)
   */
  contributionRefunds?: Array<ContributionRefund>;

  /**
   * General questions (pre insurances, bonity, transfer values)
   */
  generalQuestions?: GeneralQuestionProps;

  /**
   * Health questions props
   */
  healthQuestions?: HealthQuestionsProps;
};

/**
 * Props for a reminders (mahnungen) widget
 */
export type ContractReminderProps = {
  reminders: Array<{
    /**
     * Due date
     * (ISO compatible date format)
     */
    date?: string;

    /**
     * Level (severity) of the reminder
     * (already formatted)
     */
    level?: number;

    /**
     * levelText (severity) of the reminder
     */
    levelText?: number | string;

    /**
     * Reminder amount
     * (numeric value, not formatted)
     */
    amount?: number;
  }>;
};

/**
 * Contract summary widget props
 */
export type ContractSummaryProps = {
  /**
   * Value of contract (monthly price alltogether)
   * (numeric value, not formatted)
   */
  amount?: number;

  /**
   * Name of the product
   * (already formatted)
   */
  product?: string;

  /**
   * Count of insured persons
   * (numeric value)
   */
  insuredPersonsCount?: number;

  /**
   * Contract start date
   * (ISO compatible date format)
   */
  startDate?: string;

  /**
   * Contract created date
   * (ISO compatible date format)
   */
  createdDate?: string;
};

export type Document = {
  /**
   * Name of the document
   * (already formatted)
   */
  name: string;

  offerId?: string;

  /**
   * Callback used to download a document can be either:
   * - string: in this case, it is considered final URL that can be used for download
   * - function: callback function used when attempting to download the document
   */
  callback: ({ id }: { id: string }) => Promise<void>;
};

/**
 * Documents widget props
 */
export type DocumentsProps = {
  /**
   * List of documents available to download
   */
  documents: Array<Document>;
};

/**
 * Accounts (Konto) widget props
 */
export type AccountsProps = {
  accounts?: Array<{
    /**
     * Type of the account
     * (already formatted)
     */
    type?: string;

    /**
     * Name of the account owner
     * (already formatted)
     */
    owner?: string;

    /**
     * Inkasso Art
     * (already formatted)
     */
    collectionType?: string;

    /**
     * Name of the bank
     * (already formatted)
     */
    bankName?: string;

    /**
     * IBAN
     * (already formatted)
     */
    iban?: string;

    /**
     * BIC code
     * (already formatted)
     */
    bic?: string;

    /**
     * Valid from date
     * (ISO compatible date format)
     */
    validFrom?: string;
  }>;
};

/**
 * Additional contracts info widget props
 */
export type AdditionalContractInfoProps = {
  /**
   * Ausfertigungsgrund
   * (already formatted)
   */
  reasonForEdit?: string;

  /**
   * Letzte Änderung
   * (ISO compatible date format)
   */
  lastUpdated?: string;

  /**
   * Versicherungs-Zeitraum
   * (ISO compatible date format)
   */
  startDate?: string;

  /**
   * Antragsdatum
   * (ISO compatible date format)
   */
  applicationDate?: string;

  /**
   * Gesamtsumme
   * (numeric value, not formatted)
   */
  sumTotal?: number;

  /**
   * Zwischensumme KV
   * (numeric value, not formatted)
   */
  amountKV?: number;

  /**
   * Zwischensumme PPV
   * (numeric value, not formatted)
   */
  amountPPV?: number;

  /**
   * Betrag Beitragsrückerstattung
   * (numeric value, not formatted)
   */
  amountBRE?: number;

  /**
   * Verbleibender Betrag nach Beitragsrückerstattung und Risikozuschlägen
   * (numeric value, not formatted)
   */
  amountWithoutRZUBRE?: number;

  /**
   * Unbegrenzt steuerlich abzugsfähiger Beitrag (vor Rückerstattungen)
   * (numeric value, not formatted)
   */
  amountSTABZ?: number;

  /**
   * Besondere Vertragsbedingungen bestehen für Person(en)
   * (already formatted)
   */
  specialConditions?: string;

  /**
   * Detailed explanation of the special conditions
   * (already formatted)
   */
  specialConditionsExplanation?: string;
};

/**
 * Agent & provision data widget props
 */
export type AgentProps = PersonProps & {
  /**
   * Vermittlernummer
   * (already formatted)
   */
  agentId?: string;

  provisions?: Array<{
    /**
     * BV Tariftyp
     * (already formatted)
     */
    tariffType?: string;

    /**
     * BV Provision Stichtag
     * (ISO compatible date format)
     */
    comissionReferenceDate?: string;

    /**
     * Zahlungsweise
     * (already formatted)
     */
    paymentFrequency?: string;

    /**
     * BV %,
     * (numeric value, not formatted)
     */
    rate?: number;

    /**
     * BV Summe
     * (numeric value, not formatted)
     */
    amount?: number;
  }>;
};

export type OrganizationProps = PersonProps & {
  /**
   * Schlüsseltext
   */
  organizationUnit?: string;

  /**
   * Firmenname
   */
  companyName?: string;
};

export type HealthQuestion = {
  /**
   * ID of the question
   * (required for indexing only, not rendered)
   */
  id: string;

  /**
   * Text of the question, also with question number if applicable
   * (already formatted)
   */
  text: string;

  /**
   * Answer to the question
   * (already formatted)
   */
  answer: string;

  /**
   * Further diagnosis data
   * (items not formatted, using the original data model)
   */
  details?: Array<HealthQuestionDetail>;
};

export type HealthQuestionsProps = {
  /**
   * Adapts styles for view360 (same components are reused for Summary wizard step and 360view)
   */
  isView360?: boolean;

  /**
   * Mitteilung über die Folgen einer Verletzung der gesetzlichen Anzeigepflicht geöffnet.
   * (boolean, not formatted)
   */
  statutoryReporting?: boolean;

  /**
   * Ich habe die gesonderte Mitteilung über die Folgen einer Verletzung der gesetzlichen Anzeigepflicht zur Kenntnis genommen und verstanden, dass ich die Gesundheitsfragen nach bestem Wissen sorgfältig, vollständig und richtig beantworten muss.
   * (boolean, not formatted)
   */
  acknowledgment?: boolean;

  /**
   * List of answered health questions
   */
  questions?: Array<HealthQuestion>;
};

export type GeneralQuestionProps = {
  /**
   * Existing state insurance number
   * (already formatted)
   */
  insuranceNumber?: string;

  /**
   * Flag if partner has a specific pre-insurance
   * (boolean, not formatted)
   */
  hasHealthPreInsurance?: boolean;

  /**
   * Flag if partner has a specific pre-insurance
   * (boolean, not formatted)
   */
  hasCarePreInsurance?: boolean;

  /**
   * Flag if partner has a specific pre-insurance
   * (boolean, not formatted)
   */
  hasSickPayPreInsurance?: boolean;

  /**
   * Flag if partner has a specific pre-insurance
   * (boolean, not formatted)
   */
  hasSupplementaryPreInsurance?: boolean;

  /**
   * List of pre-insurances details
   * (same model as in wizard)
   */
  preInsurances?: Array<PreInsurance>;

  /**
   * Transfer value
   * (numeric value, not formatted)
   */
  comprehensiveHealthTransferValue?: number;

  /**
   * Transfer value
   * (numeric value, not formatted)
   */
  referenceValuePPV?: number;

  /**
   * Transfer value
   * (numeric value, not formatted)
   */
  fundsFromGZ?: number;

  /**
   * Bonity check
   * (boolean, not formatted)
   */
  hasInsuranceDept?: boolean;

  /**
   * Bonity check
   * (boolean, not formatted)
   */
  hasUsedEmergencyTariff?: boolean;

  /**
   * Bonity check
   * (boolean, not formatted)
   */
  hasInsolvency?: boolean;
};

export type SignatureStatus = {
  actionButtons?: ReactNode;
  banner?: {
    label: ReactNode;
    description: string;
    openDashboardButton?: ReactNode;
  };
};
