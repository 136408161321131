import { TariffSectionEnum } from 'models/extension-generated';

export enum WizardRoutesEnum {
  Home = '/wizard',
  PrivateData = 'private-data',
  Tariffs = 'tariffs',
  Offer = 'offer',
  GeneralQuestions = 'general-questions',
  HealthQuestions = 'health-questions',
  InsuranceHolder = 'insurance-holder',
  Summary = 'summary',
  Legal = 'legal',
  Confirm = 'confirm',
  Done = 'done'
}

/**
 * These are documents that should be downloaded together
 * with rest of the tariff documents
 */
export const STATIC_DOCUMENT_IDS = ['K06_49', 'K01_07', 'K01_19', 'K06_31', 'K01_50'];

/**
 * Document that is present if any insured person is over 60 yo
 */
export const AGE_OVER_60_DOCUMENT_ID = 'ENV61_01';

/** BRE  */
export const FINAL_YEAR_OF_BRE_AVAILABILITY = 2024;
// TL-5050

export const BRE_DOCUMENT_2024 = 'K06_123';
export const BRE_DOCUMENT_2025 = 'K06_141';
export const DEFAULT_BRE_DOCUMENT = 'K06_105';

export const DISCLAIMER_DOCUMENT = 'K01_07';

export const INSURANCE_OPTIONS_DOCUMENT = 'K06_09_1_2023';

export const INTERNATIONAL_TRAVEL_HEALTH_INSURANCE_DOCUMENT = 'K01_29';
export const DAILY_CARE_ALLOWANCE_DOCUMENT = 'K02_114';

/**
 * Tariff ids that can't be deleted
 */
export const UNDELETEABLE_TARIFF_IDS = ['PVN', 'PVB'];
