import { ReactNode } from 'react';

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import Radio from '@mui/material/Radio';

import { Checkbox } from 'lkh-portal-ui-library';

export type SelectOption = {
  value: Array<string>;
  label: string | ReactNode;
};

type SelectProps = {
  /**
   * All options to display
   */
  options: Array<SelectOption>;

  /**
   * Which options are currently selected
   */
  selected?: Array<string>;

  /**
   * Callback on change of the selection
   * @param value selected values
   * @returns void
   */
  onChange?: (value: Array<string>) => void;

  isCheckbox?: boolean;
};

type MappedOption = SelectOption & { id: string };

export const Select = ({ options, selected, onChange, isCheckbox = false }: SelectProps) => {
  const selectOptionsMap: Array<MappedOption> = options.map((option, index) => {
    return {
      ...option,
      id: `selectValue_${index}`
    };
  });

  const isSelected = (id: string): boolean => {
    const option = selectOptionsMap.find((option) => option.id === id);

    if (!option) {
      return false;
    }

    return isEqual(option.value, selected);
  };

  const handleOnSelect = (id: string) => {
    const value = selectOptionsMap.find((option) => option.id === id);
    if (isSelected(id)) {
      onChange?.([]);
    } else {
      value && onChange?.(value.value);
    }
  };

  return (
    <div className="space-y-2">
      {selectOptionsMap.map((option) => {
        const isOptionSelected = isSelected(option.id);
        return (
          <div
            key={option.value.toString()}
            className={classNames(
              'flex items-center px-[20px] py-[18px] border border-solid border-text-20',
              {
                'bg-primary-40 border-primary-80': isOptionSelected,
                'flex-row-reverse justify-between': isCheckbox
              }
            )}
            onClick={() => handleOnSelect(option.id)}
          >
            {isCheckbox ? (
              <Checkbox className="p-0 " checked={isOptionSelected} />
            ) : (
              <Radio className="p-0 mr-s" checked={isOptionSelected} />
            )}
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
