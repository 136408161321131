import { v4 } from 'uuid';

import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField, PartnerIdProp } from 'models';
import { PreInsuranceTypeEnum } from 'models/extension-generated';
import { PreInsuranceToggle } from 'models/preInsurance';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { useExtendedReducer } from '../../../../../hooks/useExtendedReducer';

const { YesNo } = Form.Components;

type PreInsuranceWrapperProps = PropsWithChildren &
  PartnerIdProp & {
    preInsuranceKey: PreInsuranceToggle;
    type: PreInsuranceTypeEnum;
  };

export const PreInsuranceWrapper = ({
  type,
  preInsuranceKey,
  partnerId,
  children
}: PreInsuranceWrapperProps) => {
  const { state, dispatch } = useHealthInsuranceContext();
  const { createPreInsurance, clearByType, updateMultipleValues } = useExtendedReducer(dispatch);

  const { t } = useTranslation('wizardGeneral');

  function create() {
    createPreInsurance({ id: v4(), type, partnerId });
  }

  const toggleKey = partnerField(partnerId, `applicationInformation.${preInsuranceKey}`);
  const hasPreInsurance = formModelGet(state.model, toggleKey);

  function handleToggleChange(newSelectionValue: boolean) {
    if (newSelectionValue === true) {
      create();
    } else {
      clearByType({ partnerId, type });
    }

    // reset dependent values
    if (type === PreInsuranceTypeEnum.HEALTH && newSelectionValue === false) {
      updateMultipleValues([
        {
          key: partnerField(partnerId, 'applicationInformation.fundsFromGZ'),
          value: ''
        },
        {
          key: partnerField(partnerId, 'applicationInformation.comprehensiveHealthTransferValue'),
          value: ''
        }
      ]);
    }
  }

  return (
    <>
      <div className="grid-res mt-[0px]">
        <div>
          <YesNo
            componentKey={toggleKey}
            question={t(`longTerm.questions.${preInsuranceKey}.text`)}
            onChange={(selected) => handleToggleChange(selected.value as boolean)}
          />
        </div>
      </div>

      {hasPreInsurance && (
        <>
          {children}
          {type === PreInsuranceTypeEnum.SUPPLEMENTARY && (
            <div className="grid-res">
              <div>
                <Divider className="mb-[32px]" />
                <Button color="primary" variant="outlined" onClick={create}>
                  {t(`longTerm.preInsurance.fields.add`)}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
