import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { Form, formModelGet, TooltipInfo } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { TariffSectionEnum } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { OptionalSection } from '../../../components/OptionalSection';
import { getOptions } from '../../../components/OptionalSection/helpers';
import { SectionTitle } from '../../../components/SectionTitle';
import { TariffSteps } from '../../../components/TariffSteps';
import { RequiresTariffsProvider } from '../../../contexts/TariffsContext';
import { DetailsLink } from '../DetailsLink/DetailsLink';
import { SickBenefitsTariffDetails } from './SickBenefitsTariffDetails';
import { WorkIncomeLast12MonthsTooltip } from './Tooltips/WorkIncomeLast12MonthsTooltip';
import { INSURANCE_OPTIONS_DOCUMENT } from 'pages/PrivateHealthInsurance/constants';

const { Input } = Form.Components;

export const SickBenefitTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.KRANKENTAGEGELD;
  const { t } = useTranslation('wizardTariffs');
  const { partnerId, reducer, state } = useHealthInsuranceContext();
  const { updateValue } = reducer;

  const wokrkIncomeLast12MonthsPath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.workIncomeLast12Months`
  );
  const selfEmployedSincePath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.selfEmployedSince`
  );
  const companyTypePath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.companyType`
  );
  const employeesCountPath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.employeesCount`
  );
  const gmbhShareholderPath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.gmbhShareholder`
  );
  const hasSalaryDuringIllnessPath = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.hasSalaryDuringIllness`
  );
  const salryDuringIllnessDurationDuration = partnerField(
    partnerId,
    `applicationInformation.tariffInformation.salaryDuringIllnessDuration`
  );

  const handleDailySicknessChange = (val: boolean) => {
    if (!val) {
      updateValue({ key: wokrkIncomeLast12MonthsPath, value: '' });
      updateValue({ key: selfEmployedSincePath, value: undefined });
      updateValue({ key: companyTypePath, value: undefined });
      updateValue({ key: employeesCountPath, value: undefined });
      updateValue({ key: gmbhShareholderPath, value: undefined });
      updateValue({ key: hasSalaryDuringIllnessPath, value: undefined });
      updateValue({ key: salryDuringIllnessDurationDuration, value: undefined });
    }
  };

  const options = getOptions(t(`sections.${SECTION}.noAnswer`));
  const hasDailySicknessAllowancePath =
    'applicationInformation.tariffInformation.hasDailySicknessAllowance';
  const value = formModelGet(state.model, partnerField(partnerId, hasDailySicknessAllowancePath));
  const selected = options.find((opt) => opt.value === value);

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <OptionalSection
        selected={selected}
        title={
          <SectionTitle
            title={
              <div className="flex gap-[8px] items-center content-center">
                <Typography variant="bodyLGBold" className="text-text-80">
                  {t(`sections.${SECTION}.question`)}
                </Typography>
                <DetailsLink documentId={INSURANCE_OPTIONS_DOCUMENT} />
              </div>
            }
          />
        }
        noAnswerText={t(`sections.${SECTION}.noAnswer`)}
        componentKey={hasDailySicknessAllowancePath}
        onChange={handleDailySicknessChange}
      >
        <div>
          <div className="grid-my-res">
            <div className="flex justify-start items-center space-x-2 mb-[0px] s:mb-[16px]">
              <div>
                <Typography variant="bodyLGBold">
                  {t(`sections.${SECTION}.workIncomeLast12Months`)}
                </Typography>
              </div>
              <div>
                <TooltipInfo dark text={<WorkIncomeLast12MonthsTooltip />} />
              </div>
            </div>
            <div className="w-[210px]">
              <Input
                type="number"
                className="w-full"
                componentKey={wokrkIncomeLast12MonthsPath}
                placeholder="0"
                unitText="€"
              />
            </div>
          </div>
          <TariffSteps
            title={t(`sections.${SECTION}.stepperHeader`)}
            stepLabel={t(`utils.perDay`, {
              ns: 'wizardTariffs'
            })}
          />
          <SickBenefitsTariffDetails />
        </div>
      </OptionalSection>
    </RequiresTariffsProvider>
  );
};
