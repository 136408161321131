import i18next from 'i18next';

import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';

export const addPartnerButtonPages = [
  WizardRoutesEnum.Tariffs,
  WizardRoutesEnum.PrivateData,
  WizardRoutesEnum.Offer,
  WizardRoutesEnum.GeneralQuestions,
  WizardRoutesEnum.HealthQuestions
];

export const downloadOfferPages = [
  WizardRoutesEnum.Tariffs,
  WizardRoutesEnum.Offer,
  WizardRoutesEnum.GeneralQuestions,
  WizardRoutesEnum.HealthQuestions,
  WizardRoutesEnum.InsuranceHolder,
  WizardRoutesEnum.Legal,
  WizardRoutesEnum.Confirm,
  WizardRoutesEnum.Done
];

export const downloadProtocolPages = [WizardRoutesEnum.Tariffs];

export const noNextButtonIconPages = [WizardRoutesEnum.Summary];

export const getNextButtonText = (pathName: string) => {
  if (pathName === WizardRoutesEnum.PrivateData) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStepHealth');
  } else if (pathName === WizardRoutesEnum.InsuranceHolder) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStepInsHold');
  } else if (pathName === WizardRoutesEnum.Summary) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStepSum');
  } else if (pathName === WizardRoutesEnum.Legal) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStepLegal');
  } else if (pathName === WizardRoutesEnum.Confirm) {
    return i18next.t('wizardPrivateHealthInsurance:actions.nextStepConfirmation');
  }
  return i18next.t('wizardPrivateHealthInsurance:actions.nextStep');
};
