import { useEffect, useReducer, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import { HealthQuestion, HealthQuestionDetail, Question } from 'models/extension-generated';

import { DiagnosisAccordion } from './DiagnosisAccordion';
import { DetailValue } from './DiagnosisForm';
import { DetailActions, diagnosisReducer, Errors } from './diagnosisReducer';

export type HealthQuestionDetailJoined = HealthQuestionDetail & {
  question?: { id: string; order: string };
};

export const DiagnosisModal = ({
  isOpen,
  questions,
  diagnoses,
  healthQuestions,
  onDialogClose,
  onSaveAndClose
}: {
  isOpen: boolean;
  questions: Array<Question>;
  healthQuestions: Array<HealthQuestion>;
  diagnoses: Map<string, HealthQuestionDetailJoined>;
  onDialogClose: () => void;
  onSaveAndClose: (diagnoses: Array<HealthQuestionDetailJoined>) => void;
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const { t } = useTranslation('wizardHealth', {
    keyPrefix: 'section.diagnosis'
  });
  const [state, dispatch] = useReducer(diagnosisReducer, {
    isTouched: false,
    details: diagnoses,
    questions: questions,
    healthQuestions: healthQuestions,
    accordionsState: new Map<string, boolean>(),
    newDetails: new Map<string, HealthQuestionDetailJoined>()
  });

  const newDetails = Array.from(state.newDetails.values()).sort((a, b) => b.orderNo - a.orderNo);
  const arrayOfDetails = Array.from(state.details.values()).sort((a, b) => b.orderNo - a.orderNo);

  const allDetails = [...newDetails, ...arrayOfDetails].sort((a, b) => b.orderNo - a.orderNo);

  const isEmpty = allDetails.length === 0;
  const yesQuestions = healthQuestions.filter((question) => question.answer === true);
  const yesQuestionsDefinition = yesQuestions.map((question) => {
    const { order, id } = question;
    const questionConfig = questions.find((q) => q.id === id);
    return {
      order,
      id,
      text: questionConfig?.text || ''
    };
  });
  const yesQuestionsOptions = yesQuestionsDefinition.map((question) => {
    const { order, id, text } = question;
    return {
      value: id,
      label: `${order}. ${text}`
    };
  });

  // add empty diagnosis if it is empty and modal is open
  useEffect(() => {
    if (isEmpty && isOpen && diagnoses.size === 0) {
      dispatch({
        type: DetailActions.CREATE_DETAIL_TEMPLATE,
        payload: {}
      });
    }
    if (isOpen) {
      dispatch({
        type: DetailActions.SET_DETAILS,
        payload: diagnoses
      });
    }
  }, [isOpen]);

  const validateDiagnosis = (diagnose: HealthQuestionDetailJoined): Errors => {
    const {
      question,
      diagnosis,
      // doctor,
      // sickLeaveDuration,
      treatmentType,
      symptomsFreeFrom,
      hasConsenquences,
      symptomsFreeFollowUpDiagnosisAndTreatment,
      treatmentStart,
      treatmentEnd
    } = diagnose;
    const errors: Errors = new Map();

    if (!diagnosis) {
      errors.set('diagnosis', true);
    }

    if (!treatmentType) {
      errors.set('treatmentType', true);
    }

    if (hasConsenquences === null || hasConsenquences === undefined) {
      errors.set('hasConsenquences', true);
    }

    if (hasConsenquences === true && !symptomsFreeFrom) {
      errors.set('symptomsFreeFrom', true);
    }

    if (hasConsenquences === false && !symptomsFreeFollowUpDiagnosisAndTreatment) {
      errors.set('symptomsFreeFollowUpDiagnosisAndTreatment', true);
    }

    if (!treatmentStart) {
      errors.set('treatmentStart', true);
    }
    if (!treatmentEnd) {
      errors.set('treatmentEnd', true);
    }

    if (!question || !question.id || !question.order) {
      errors.set('question', true);
    }

    return errors;
  };

  const handleSaveAndClose = () => {
    const newDiagnoses = Array.from(state.newDetails.values());

    const errors: Map<string, Errors> = new Map();
    newDiagnoses.forEach((diagnose) => {
      const error = validateDiagnosis(diagnose);
      if (error.size > 0) {
        errors.set(diagnose.id || '', error);
      }
    });
    diagnoses.forEach((diagnose) => {
      const error = validateDiagnosis(diagnose);

      if (error.size > 0) {
        errors.set(diagnose.id || '', error);
      }
    });

    dispatch({
      type: DetailActions.SET_ERRORS,
      payload: errors
    });

    if (errors.size === 0) {
      dispatch({
        type: DetailActions.RESET_STATE,
        payload: undefined
      });
      toast.success(t<string>('saveAndCloseSuccess'));
      onSaveAndClose([...newDiagnoses, ...arrayOfDetails]);
    }
  };

  const handleNewDiagnosisChange = (
    id: string,
    value: DetailValue,
    key: keyof HealthQuestionDetailJoined
  ) => {
    dispatch({
      type: DetailActions.SET_DETAIL_PROPERTY,
      payload: {
        id,
        key,
        value
      }
    });
  };

  const handleDiagnosisDelete = (id: string) => {
    dispatch({
      type: DetailActions.DELETE_DIAGNOSIS,
      payload: {
        id
      }
    });
  };

  const handleOnCloseClick = () => {
    const shouldConfirm = newDetails.length > 0 || arrayOfDetails.length > 0;
    if (shouldConfirm && state.isTouched) {
      setIsConfirmDialogOpen(true);
    } else {
      onDialogClose();
    }
  };

  const handleConfirmedClose = () => {
    dispatch({
      type: DetailActions.RESET_STATE,
      payload: undefined
    });
    setIsConfirmDialogOpen(false);
    onDialogClose();
  };

  const handleOnDuplicate = (detail: HealthQuestionDetailJoined) => {
    dispatch({
      type: DetailActions.DUPLICATE,
      payload: detail
    });

    toast.success(t<string>('duplicationSuccess'));
  };

  return (
    <>
      {/* Confirmation dialog before closing modal */}
      <Dialog maxWidth="sm" open={isConfirmDialogOpen}>
        <DialogTitle className="flex flex-row items-center px-[32px] py-[24px]">
          <ErrorIcon className="self-baseline text-danger-60 mr-[16px]" />
          <div className="flex flex-col">
            <Typography
              fontWeight={700}
              fontSize="16px"
              lineHeight="20px"
              className="text-text-100 mb-[8px]"
            >
              {t('confirmation.title')}
            </Typography>
            <Typography fontSize="14px" lineHeight="20px" className="text-text-80">
              {t('confirmation.description')}
            </Typography>
          </div>
        </DialogTitle>
        <DialogActions className="flex justify-end border-none px-[32px] py-[24px]">
          <Button
            size="large"
            variant="text"
            onClick={() => {
              setIsConfirmDialogOpen(false);
            }}
          >
            {t('confirmation.cancel')}
          </Button>

          <Button size="large" variant="contained" color="error" onClick={handleConfirmedClose}>
            {t('confirmation.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diagnosis dialog */}
      <Dialog fullWidth maxWidth="xl" open={isOpen}>
        <DialogTitle className="flex flex-row justify-between items-center border border-solid border-b-2 border-text-20">
          <Typography fontSize="14px" lineHeight="20px" className="text-text-100 font-bold">
            {t('title')}
          </Typography>
          <IconButton onClick={handleOnCloseClick}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* Newly added diagnoses */}
        <DialogContent
          className={classNames('min-h-[40vh] p-0', {
            'flex justify-center items-center': isEmpty
          })}
        >
          {isEmpty && (
            <div className="w-1/2 text-center">
              <Typography
                fontSize={'18px'}
                lineHeight={'40px'}
                className="tracking-wide text-text-40"
              >
                {t('noDiagnosesAdded')}
              </Typography>
            </div>
          )}
          {allDetails.map((detail) => {
            const diagnosisError = state.errors?.get(detail.id || '') || new Map();
            const isCompleted = !!Array.from(diagnoses.values()).find((d) => d.id === detail.id);

            return (
              <DiagnosisAccordion
                isCompleted={isCompleted}
                openOverride={state.accordionsState.get(detail.id || '')}
                isExpanded={state.accordionsState.get(detail.id || '')}
                key={detail.id}
                detail={detail}
                errors={diagnosisError}
                healthQuestions={healthQuestions}
                yesQuestionsOptions={yesQuestionsOptions}
                handleNewDiagnosisChange={handleNewDiagnosisChange}
                onDelete={handleDiagnosisDelete}
                onDuplicate={handleOnDuplicate}
              />
            );
          })}
        </DialogContent>

        <DialogActions className="flex justify-between">
          <Button
            size="large"
            variant="text"
            className="flex items-center"
            onClick={() => {
              dispatch({
                type: DetailActions.CREATE_DETAIL_TEMPLATE,
                payload: {}
              });
            }}
          >
            <AddCircleOutlineIcon className="mr-[8px]" />
            {t('addNewDiagnosis')}
          </Button>

          <div>
            <Button size="large" variant="text" className="mr-[16px]" onClick={handleOnCloseClick}>
              {t('cancelBtn')}
            </Button>

            <Button size="large" variant="contained" onClick={handleSaveAndClose}>
              {t('addButtonTitle')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
