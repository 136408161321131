import classNames from 'classnames';

import { Chip } from '@mui/material';
import Typography from '@mui/material/Typography';

import { DarkTooltip } from 'lkh-portal-ui-library';

import styles from './TariffOption.module.scss';

type TariffOptionProps = {
  /**
   * Label of the tariff type (shown on the left)
   */
  label?: string;

  /**
   * Optional description shown after the chips (usually option text)
   */
  description?: string;

  /**
   * List of represented tariffs (one item = one chip)
   */
  options?: Array<string>;
};

export const TariffOption = ({ label, options, description }: TariffOptionProps) => {
  return (
    <div className={classNames('transition-all', styles.optionLabel)}>
      {label && <Typography className="m-r-24">{label}</Typography>}
      {options?.map((opt) => (
        <DarkTooltip title={opt}>
          <Chip
            label={
              <Typography component="div" noWrap variant="bodySMRegular" className="truncate ">
                {opt}
              </Typography>
            }
            className={classNames(
              'rounded-[8px] text-white-100 bg-highlight-80 mr-[24px] flex-shrink min-w-[90px] flex'
            )}
            variant="filled"
            color="primary"
          />
        </DarkTooltip>
      ))}
      {description && <Typography className="m-0 p-r-12">{description}</Typography>}
    </div>
  );
};
