import { PropsWithChildren, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { DarkTooltip, Form } from 'lkh-portal-ui-library';
import { partnerField, RecursiveKeyOf } from 'models';
import { HealthQuestion, HealthQuestionDetail, Partner } from 'models/extension-generated';

import { HealthQuestionDetailJoined } from './DiagnosesModal';
import { Errors } from './diagnosisReducer';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

const Title = (
  props: PropsWithChildren & {
    className?: string;
  }
) => {
  return (
    <Typography
      component={'div'}
      fontSize="14px"
      lineHeight="20px"
      className={`text-text-80 font-bold ${props.className}`}
    >
      {props.children}
    </Typography>
  );
};
enum Bool {
  Yes = 'Yes',
  No = 'no'
}

export type DetailValue =
  | string
  | boolean
  | Array<string>
  | undefined
  | null
  | { id: string; order: string };

const getDiagnosisKey: ({
  partnerId,
  healthQuestionId,
  diagnosisId,
  property
}: {
  partnerId: string;
  healthQuestionId: string;
  diagnosisId: string;
  property: keyof HealthQuestionDetailJoined;
}) => string = ({ partnerId, healthQuestionId, diagnosisId, property }) => {
  const fieldPath =
    `applicationInformation.health[${healthQuestionId}].details[${diagnosisId}].${property}` as RecursiveKeyOf<Partner>;
  return partnerField(partnerId, fieldPath);
};

export const DiagnosisForm = (props: {
  yesQuestionsOptions: Array<{ label: string; value: string }>;
  error: Errors;
  healthQuestions: HealthQuestion[];
  diagnosis?: HealthQuestionDetailJoined;
  onChange: (value: DetailValue, key: keyof HealthQuestionDetailJoined) => void;
}) => {
  const { partnerId } = useHealthInsuranceContext();
  const { diagnosis } = props;
  const healthQuestionLabel = props.yesQuestionsOptions.find(
    (q) => q.value === props.diagnosis?.question?.id
  )?.label;

  const diagnosisId = diagnosis?.id || '';
  const healthQuestionId = diagnosis?.question?.id || '';
  const diagnosisKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'diagnosis'
  });
  const symptomsFreeFromKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'symptomsFreeFrom'
  });
  const treatmentStartKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'treatmentStart'
  });
  const treatmentEndKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'treatmentEnd'
  });
  const hasOperationKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'hasOperation'
  });
  const hasConsequencesKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'hasConsenquences'
  });
  const treatmentTypeKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'treatmentType'
  });

  const { hasError: diagnosisError } = Form.hooks.useFormComponent(diagnosisKey);
  const { hasError: treatmentTypeError } = Form.hooks.useFormComponent(treatmentTypeKey);
  const { hasError: symptomsFreeFromError } = Form.hooks.useFormComponent(symptomsFreeFromKey);
  const { hasError: symptomsFreeFollowUpDiagnosisAndTreatmentError } =
    Form.hooks.useFormComponent(symptomsFreeFromKey);
  const { hasError: treatmentStartError } = Form.hooks.useFormComponent(treatmentStartKey);
  const { hasError: treatmentEndError } = Form.hooks.useFormComponent(treatmentEndKey);
  const { hasError: hasOperationError } = Form.hooks.useFormComponent(hasOperationKey);
  const { hasError: hasConsequencesError } = Form.hooks.useFormComponent(hasConsequencesKey);

  const hasConsequences = (() => {
    if (diagnosis?.hasConsenquences === true) {
      return Bool.Yes;
    }
    if (diagnosis?.hasConsenquences === false) {
      return Bool.No;
    }
    return null;
  })();

  const hasOperation = (() => {
    if (diagnosis?.hasOperation === true) {
      return Bool.Yes;
    }
    if (diagnosis?.hasOperation === false) {
      return Bool.No;
    }
    return null;
  })();

  const { t } = useTranslation('wizardHealth', {
    keyPrefix: 'section.diagnosis'
  });

  const handleOnChange = (key: keyof HealthQuestionDetailJoined, value: DetailValue) => {
    props.onChange(value, key);
  };

  const commonTextFieldProps = useMemo<TextFieldProps>(
    () => ({
      rows: 5,
      multiline: true,
      placeholder: t('attributes.placeholder'),
      label: t('attributes.answer'),
      fullWidth: true,
      FormHelperTextProps: {
        className: 'text-right mt-[-20px]'
      }
    }),
    []
  );
  return (
    <div className="space-y-[16px]">
      <div>
        <div className="flex flex-col justify-between">
          <Title className="mb-[16px]">{t('attributes.diagnose')} </Title>
          <TextField
            {...commonTextFieldProps}
            error={props.error.get('diagnosis') || diagnosisError}
            value={props.diagnosis?.diagnosis}
            onChange={(event) => {
              const { value } = event.target;
              if (value.length > 200) return;

              handleOnChange('diagnosis', value);
            }}
            helperText={`${props?.diagnosis?.diagnosis?.length}/200`}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-[16px]">
        <div className="flex justify-between col-span-4">
          <Title>{t('attributes.treatmentType')}</Title>
        </div>
        <div className="flex justify-between col-span-8">
          <Title>{t('attributes.treatmentTypeDuration')}</Title>
        </div>

        <div className="flex items-center col-span-4">
          <FormControl error={treatmentTypeError}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={diagnosis?.treatmentType}
              onChange={(_event, value) => {
                handleOnChange('treatmentType', value);
              }}
            >
              <FormControlLabel
                value={HealthQuestionDetail.treatmentType.AMBULANT}
                control={<Radio />}
                label={t('attributes.treatmentTypeOption.ambulant')}
              />
              <FormControlLabel
                value={HealthQuestionDetail.treatmentType.STATIONER}
                control={<Radio />}
                label={t('attributes.treatmentTypeOption.stationary')}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="flex items-center col-span-4">
          <div className="grid grid-cols-2 gap-[16px] py-[16px]">
            <DatePicker
              views={['year', 'month']}
              format="MM.yyyy"
              label={t('attributes.start')}
              value={
                props.diagnosis?.treatmentStart ? new Date(props.diagnosis.treatmentStart) : null
              }
              slotProps={{
                textField: {
                  error: props.error.get('treatmentStart') || treatmentStartError
                }
              }}
              onChange={(value, context) => {
                if (context.validationError) return;

                handleOnChange('treatmentStart', value ? value.toISOString() : undefined);
              }}
            />
            <DatePicker
              views={['year', 'month']}
              label={t('attributes.end')}
              format="MM.yyyy"
              value={props.diagnosis?.treatmentEnd ? new Date(props.diagnosis.treatmentEnd) : null}
              slotProps={{
                textField: {
                  error: props.error.get('treatmentEnd') || treatmentEndError
                }
              }}
              onChange={(value, context) => {
                if (context.validationError) return;

                handleOnChange('treatmentEnd', value ? value.toISOString() : undefined);
              }}
            />
          </div>
        </div>
        <div className="grid col-span-12">
          <FormGroup className="col-span-4">
            <FormControl error={hasOperationError}>
              <Title className="mb-m">{t('attributes.operation')}</Title>
              <RadioGroup
                name="controlled-radio-buttons-group"
                row
                value={hasOperation}
                onChange={(_event, value) => {
                  handleOnChange('hasOperation', value === Bool.Yes ? true : false);
                }}
              >
                <FormControlLabel
                  value={Bool.Yes}
                  control={<Radio />}
                  label={t('attributes.operationOption.yes')}
                />
                <FormControlLabel
                  value={Bool.No}
                  control={<Radio />}
                  label={t('attributes.operationOption.no')}
                />
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </div>

        <Title className="col-span-4">{t('attributes.consenquences')}</Title>
        <Title className="col-span-8">
          {diagnosis?.hasConsenquences === false
            ? t('attributes.symptomsFreeFollowUpDiagnosisAndTreatment')
            : ''}
          {diagnosis?.hasConsenquences === true ? t('attributes.symptomsFreeFrom') : ''}
        </Title>

        <FormGroup className="col-span-4 flex justify-center">
          <FormControl error={hasConsequencesError}>
            <RadioGroup
              name="controlled-radio-buttons-group"
              row
              value={hasConsequences}
              onChange={(_event, value) => {
                handleOnChange('hasConsenquences', value === Bool.Yes ? true : false);
              }}
            >
              <FormControlLabel
                value={Bool.Yes}
                control={<Radio />}
                label={t('attributes.operationOption.yes')}
              />
              <FormControlLabel
                value={Bool.No}
                control={<Radio />}
                label={t('attributes.operationOption.no')}
              />
            </RadioGroup>
          </FormControl>
        </FormGroup>

        {diagnosis?.hasConsenquences === true && (
          <div className="col-span-8">
            <DatePicker
              className="mt-m"
              views={['year', 'month']}
              label={t('attributes.symptomsFreeFromLabel')}
              format="MM.yyyy"
              value={diagnosis?.symptomsFreeFrom ? new Date(diagnosis.symptomsFreeFrom) : null}
              slotProps={{
                textField: {
                  error: props.error.get('symptomsFreeFrom') || symptomsFreeFromError
                }
              }}
              onChange={(value, context) => {
                if (context.validationError) return;

                handleOnChange('symptomsFreeFrom', value ? value.toISOString() : undefined);
              }}
            />
          </div>
        )}
        {diagnosis?.hasConsenquences === false && (
          <div className="col-span-4">
            <TextField
              className="mt-m"
              {...commonTextFieldProps}
              error={
                props.error.get('symptomsFreeFollowUpDiagnosisAndTreatment') ||
                symptomsFreeFollowUpDiagnosisAndTreatmentError
              }
              value={diagnosis?.symptomsFreeFollowUpDiagnosisAndTreatment}
              onChange={(event) => {
                const { value } = event.target;
                if (value.length > 200) return;

                handleOnChange('symptomsFreeFollowUpDiagnosisAndTreatment', value);
              }}
              helperText={`${
                (diagnosis?.symptomsFreeFollowUpDiagnosisAndTreatment || '')?.length
              }/200`}
            />
          </div>
        )}
      </div>

      {/* Health questions dropdown */}
      <div>
        <Title className="mb-[16px]">{t('attributes.selectRelatedQuestions')}</Title>
        <DarkTooltip
          title={
            healthQuestionLabel ? (
              <Typography fontSize="12px" lineHeight={'16px'} className="tracking-wide">
                {healthQuestionLabel}
              </Typography>
            ) : null
          }
        >
          <Autocomplete
            value={props.yesQuestionsOptions.find((q) => q.value === props.diagnosis?.question?.id)}
            blurOnSelect
            options={props.yesQuestionsOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props.error.get('question')}
                label={t('healthQuestions')}
              />
            )}
            data-testid="autocomplete"
            onChange={(_event, value) => {
              const selectedQuestion = value?.value;
              const question = props.healthQuestions.find((q) => q.id === selectedQuestion);

              handleOnChange('question', {
                id: question?.id || '',
                order: question?.order.toString() || '0'
              });
            }}
          />
        </DarkTooltip>
      </div>
    </div>
  );
};
