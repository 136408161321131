import { WizardSlot } from 'layout/wizard/slots';

import { Form } from 'lkh-portal-ui-library';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { EmploymentData } from './EmploymentData';
import { TariffQuestions } from './TarriffQuestions';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { PrivateDataPictogram } from 'pages/PrivateHealthInsurance/components/pictograms';
import { StepHeading } from 'pages/PrivateHealthInsurance/components/StepHeading';

export const TariffDataStep = () => {
  const { state, dispatch, partnerId } = useHealthInsuranceContext();
  const { getStepHeading, formatPartnerName, getPartnerById } = useApplicationHelpers(state.model);
  const partner = getPartnerById(partnerId);
  const name =
    partner.firstname || partner.lastname
      ? formatPartnerName(partnerId)
      : getStepHeading(partner.id || '');

  return (
    <>
      <StepHeading icon={<PrivateDataPictogram />} title={name} showProgress />
      <div className="layout-res">
        <WizardSlot.Main>
          <Form state={state} dispatch={dispatch}>
            <EmploymentData />
            <TariffQuestions />
          </Form>
        </WizardSlot.Main>
      </div>
    </>
  );
};
