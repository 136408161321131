import { useMemo } from 'react';

import { partnerField } from 'models';
import { Tariff } from 'models/extension-generated';

import { useProvidedTariffs } from '../../contexts/TariffsContext';
import { Select, SelectOption } from '../Select';
import { TariffOption } from '../TariffOption';

type SupplementaryTariffProps = {
  tariff: Tariff;
};

export const SupplementaryTariff = ({ tariff }: SupplementaryTariffProps) => {
  const { partnerId, reducer, sectionTariffs, selectedTariffs, updateHealthQuestions } =
    useProvidedTariffs();
  const { updateValue, giveTariff, removeTariff } = reducer;
  const selected = selectedTariffs.map((t) => t.id);
  const options: Array<SelectOption> = useMemo(
    () => [
      {
        value: [tariff.id],
        label: <TariffOption options={[tariff.id || '']} description={tariff.options?.join(' ')} />
      }
    ],
    [sectionTariffs]
  );

  function handleChange(tariffs: Array<string>): void {
    const hasSelection = tariffs.length > 0;

    if (hasSelection) {
      giveTariff({ partnerId, tariff });
    } else {
      removeTariff({ partnerId, tariff });
    }

    updateValue({
      key: partnerField(
        partnerId,
        'applicationInformation.tariffInformation.hasOptionForAdditionalInsurance'
      ),
      value: hasSelection
    });

    updateHealthQuestions();
  }

  return (
    <div className="grid-res mt-[0px]">
      <div>
        <Select onChange={handleChange} options={options} selected={selected} isCheckbox={true} />
      </div>
    </div>
  );
};
