import { WizardSlot } from 'layout/wizard/slots';

import { useEffect, useMemo, useState } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { applicationField, RealmRole } from 'models';
import { ConfigResponse, Partner, PersonRoleEnum, Question } from 'models/extension-generated';

import { MarketingConsentForm } from '../../components/MarketingConsentForm';
import { PaymentContributorDetailSummary } from '../Offer/components/PersonDetails/PaymentContributorDetailSummary';
import { PersonDetailSummary } from '../Offer/components/PersonDetails/PersonDetailSummary';
import { Summary as PersonalDataSummary } from '../Offer/components/Summary';
import { CustodyInformation } from './CustodyInformation/CustodyInformation';
import { PartnersSummary } from './PartnersSummary';
import { useAgentPickerData } from './useAgentPickerData';
import { getHolderFormat, ParsedPartner, parsePartner } from './utils';
import { useConfigContext } from 'contexts/ConfigContext';
import { useApplicationCalculate } from 'hooks/useApplicationCalculate';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { formatName } from 'pages/Contract360Page/utils/formatters';
import { FormAgentsSearch } from 'pages/PrivateHealthInsurance/components/AgentsSearch';
import { SelectedAgent } from 'pages/PrivateHealthInsurance/components/AgentsSearch/AgentsSearch';
import { PyramidPictogram } from 'pages/PrivateHealthInsurance/components/pictograms/PyramidPictogram';
import { StepHeading } from 'pages/PrivateHealthInsurance/components/StepHeading';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { getQuestionsByCriteria } from 'pages/PrivateHealthInsurance/context/QuestionsContext/getQuestionsByCriteria';

const { Input } = Form.Components;

const usePartnersQuestion = (partners: Partner[], insuranceStart: string) => {
  const [data, setData] = useState<{ partnerId: string; questions: Question[] }[]>();

  const fetchPartnerQuestions = async (partner: Partner) => {
    const questions = await getQuestionsByCriteria({
      birthDate: partner.birthDate,
      tariffs: partner.applicationInformation?.tariffInformation?.selectedTariffs || [],
      insuranceStart: insuranceStart
    });
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      partnerId: partner.id!,
      questions: questions
    };
  };

  useEffect(() => {
    const fetchQuestionsFn = async () => {
      const questions = await Promise.all(partners.map(fetchPartnerQuestions));
      setData(questions);
    };
    if (partners.length > 0) {
      fetchQuestionsFn();
    }
  }, []);

  return { data };
};

type RenderHolderAndContributorProps = {
  config: ConfigResponse;
  insuranceStart: string;
  holderId: string;
  getPartnersByRole: (role: PersonRoleEnum) => Array<Partner>;
};

const RenderHolderAndContributor = ({
  config,
  insuranceStart,
  holderId,
  getPartnersByRole
}: RenderHolderAndContributorProps) => {
  const { t } = useTranslation('policy');
  const holder: ParsedPartner = parsePartner(getPartnersByRole(PersonRoleEnum.POLICY_HOLDER)[0]);
  const contributor: ParsedPartner = parsePartner(
    getPartnersByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR)[0]
  );
  const _holder = useMemo(() => {
    return getHolderFormat(holder, config);
  }, [holder, config.countries]);
  const _contributor = useMemo(() => {
    return getHolderFormat(contributor, config);
  }, [contributor, config.countries]);

  return (
    <div>
      <PersonalDataSummary
        name={formatName(holder)}
        applicationStart={insuranceStart}
        policyOrPaymentHolder
        subHeading={t('policyHolder')}
      />
      <PersonDetailSummary {..._holder} />
      <div className="mt-[48px]">
        <PersonalDataSummary
          name={formatName(contributor)}
          applicationStart={insuranceStart}
          policyOrPaymentHolder
          subHeading={t('payer')}
        />
        <PaymentContributorDetailSummary
          {..._contributor}
          isPolicyHolder={holderId === contributor.id}
        />
      </div>
    </div>
  );
};

export const Summary = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { config } = useConfigContext();
  const { state, dispatch, reducer } = useHealthInsuranceContext();
  const { getPartnersByRole, getSinglePartnerByRole } = useApplicationHelpers(state.model);

  const [agent, setAgent] = useAgentPickerData();

  const { keycloak } = useKeycloak();
  const isServiceAccount = keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);
  const isAgentIdInputDisabled = !isServiceAccount;

  const { isLoading, data } = useApplicationCalculate(state.model, []);

  const partners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const insuranceStartKey = applicationField('applicationStart');
  const insuranceStart: string = formModelGet(state.model, insuranceStartKey);
  const { data: questionsData } = usePartnersQuestion(partners, insuranceStart);

  const subAgentId = applicationField('subAgentId');
  const holderId = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER).id || '';

  const onAgentChange = (selected: SelectedAgent | null) => {
    const agentIdPath = applicationField('agentId');

    // update local state and form state
    setAgent(selected);
    reducer.updateValue({
      key: agentIdPath,
      value: selected?.id || ''
    });
  };

  return (
    <>
      <StepHeading icon={<PyramidPictogram />} title={t('summary.title')} />
      <div className="layout-res">
        <WizardSlot.Main>
          <Form state={state} dispatch={dispatch}>
            <div className="gap-[8px]">
              <Typography variant="bodyLGBold" translate="no" className={'text-text-100 grid-res'}>
                Vorgangszuweisung an Vermittler/in
              </Typography>
              <div className="grid-res-2">
                <FormAgentsSearch
                  disabled={isAgentIdInputDisabled}
                  agent={agent}
                  onAgentChange={onAgentChange}
                />
                <Input componentKey={subAgentId} label="Untervermittlernummer (optional)" />
              </div>
            </div>
            <div className="grid-res my-0">
              <RenderHolderAndContributor
                config={config}
                insuranceStart={insuranceStart}
                holderId={holderId}
                getPartnersByRole={getPartnersByRole}
              />
            </div>
            <div className="space-y-[48px] mt-[48px]">
              <PartnersSummary
                partners={partners}
                data={data}
                questionsData={questionsData}
                isLoading={isLoading}
                insuranceStart={insuranceStart}
                config={config}
              />
            </div>
            <CustodyInformation />
            <MarketingConsentForm holderId={holderId} />
          </Form>
        </WizardSlot.Main>
      </div>
    </>
  );
};
