/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Bedarfsituation
 */
export enum NeedSituationEnum {
    ANU_P_U = 'ANuP_U',
    SU_P_U = 'SuP_U',
    KANS_U = 'KANS_U',
    HMU_P_U = 'HMuP_U',
    KHM_U = 'KHM_U',
    SU_S_U = 'SuS_U',
    ANU_P = 'ANuP',
    SU_P = 'SuP',
    KANS = 'KANS',
    HMU_P = 'HMuP',
    KHM = 'KHM',
    SU_S = 'SuS',
    BU_P = 'BuP',
    BAU_P = 'BAuP',
    KB = 'KB',
    ZVGKV = 'ZVGKV',
    ZVGKV_U = 'ZVGKV_U',
}
