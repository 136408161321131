import { ReactNode } from 'react';

import classNames from 'classnames';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Form, Input } from 'lkh-portal-ui-library';
import { partnerField } from 'models';

import styles from './StepPicker.module.scss';

const { Field } = Form.Components;

export type StepOption = {
  key: string;
  stepLabel: string | ReactNode;
  stepUnit?: string;
  isEnabled: boolean;
  value: boolean | number | undefined;
};

type StepPickerProps = {
  partnerId: string;
  steps: Array<StepOption>;
  valueLabel: string;
  unit?: string;
  onChange?: (key: string, property: keyof StepOption, value: boolean | number | undefined) => void;
};

export const StepPicker = ({ steps, valueLabel, unit, onChange, partnerId }: StepPickerProps) => {
  function handleSetEnabled(step: StepOption, value: boolean): void {
    if (!value) {
      onChange?.(step.key, 'value', undefined);
    }
    onChange?.(step.key, 'isEnabled', value);
  }

  function handleStepValue(step: StepOption, value: number): void {
    onChange?.(step.key, 'value', value);
  }

  return (
    <div className={styles.stepPickerWrapper}>
      {steps.map((step) => {
        const highMoneyAmountPath = partnerField(
          partnerId,
          `applicationInformation.tariffInformation.selectedTariffs[${step.key}].highMoneyAmount`
        );
        const { hasError, message } = Form.hooks.useFormComponent(highMoneyAmountPath);

        return (
          <div
            key={step.key}
            className={classNames(styles.step, { [styles.enabled]: step.isEnabled })}
          >
            <div className={styles.heading}>
              <div className="flex items-center">
                <Chip
                  label={step.key}
                  className="rounded-[8px] text-white-100 mr-[24px] min-w-[90px] flex"
                  variant="filled"
                  color="primary"
                />
                <Typography variant="bodySMRegular">{step.stepLabel}</Typography>
              </div>
              <IconButton onClick={() => handleSetEnabled(step, !step.isEnabled)} size="small">
                {step.isEnabled ? (
                  <RemoveCircleOutlineOutlinedIcon />
                ) : (
                  <AddCircleOutlineOutlinedIcon />
                )}
              </IconButton>
            </div>
            {step.isEnabled && (
              <div className="flex items-center grid-my-res space-x-[32px]">
                <div className="w-[210px] ml-[24px]">
                  <Field error={hasError} message={message}>
                    <Input
                      placeholder="0"
                      className="w-full"
                      error={hasError}
                      type="number"
                      unitText={unit}
                      value={step.value === 0 ? '' : step.value?.toString()}
                      onChange={(e) => handleStepValue(step, e as number)}
                    />
                  </Field>
                </div>
                <div className={classNames({ ['pb-[64px]']: hasError })}>
                  <Typography variant="bodyMDBold">{valueLabel}</Typography>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
