import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import Button, { buttonClasses } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField, RealmRole } from 'models';
import { ApplicationService, PersonRoleEnum } from 'models/extension-generated';
import { ApiError } from 'models/portal-generated';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useApplicaitionPersistance } from '../../hooks/useApplicationPersistance';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';
import { IncompletePersonDialog } from './IncompletePersonDialog';
import { SignatureDialog } from './SignatureDialog';
import { SubmitSigned } from './SubmitSigned';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';
import { ParsedPartner, parsePartner } from 'pages/PrivateHealthInsurance/subpages/Summary/utils';

type ContinueButtonProps = {
  title: string;
  isOnLastPage: boolean;
};

export const ContinueButton = ({ title, isOnLastPage }: ContinueButtonProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const { reducer, state, partnerId } = useHealthInsuranceContext();
  const { next, ScreenOrder, currentScreen } = useWizardNavigation();
  const { save } = useApplicaitionPersistance();
  const { isValidating, validateNextPage } = useApplicationValidation(state, reducer, partnerId);
  const { getPartnersByRole } = useApplicationHelpers(state.model);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const [isSigning, setIsSigning] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isIncompletePersonDialogOpen, setIsIncompletePersonDialogOpen] = useState(false);
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const [isSubmitSignedOpen, setIsSubmitSignedOpen] = useState(false);

  const { updateValue } = reducer;
  const applicationStart = formModelGet(state.model, applicationField('applicationStart'));

  const isProcessing = isSubmitting || isValidating;
  const isServiceInternal = keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);
  const holder: ParsedPartner = parsePartner(getPartnersByRole(PersonRoleEnum.POLICY_HOLDER)[0]);

  const handleManualSignature = async () => {
    setIsSigning(true);
    try {
      const { id } = await ApplicationService.submitApplication({
        requestBody: { ...state.model }
      });
      setIsSubmitted(true);
      setIsSignatureDialogOpen(false);
      navigate(`/360/antrag/${id}`);
    } catch (error) {
      toast.error('digitalSignatureFailed');
    } finally {
      setIsSigning(false);
    }
  };

  const handleDigitalSignature = async () => {
    setIsSigning(true);

    try {
      const { id } = await ApplicationService.submitApplication({
        requestBody: { ...state.model }
      });
      setIsSubmitted(true);

      const response = await ApplicationService.signApplicationDigitally({
        id
      });
      const inSignRedirectUrl = response.inSignSession?.redirectUrl;

      if (inSignRedirectUrl) {
        window.open(inSignRedirectUrl, '_blank');
      }
      setIsSignatureDialogOpen(false);
      navigate(`/360/antrag/${id}`);
    } catch (error) {
      toast.error('digitalSignatureFailed');
    } finally {
      setIsSigning(false);
    }
  };

  const updateApplicationId = (newId: string) => {
    if (!state.model.id) {
      updateValue({ key: 'id', value: newId });
    }
  };

  const handleSubmitSigned = async () => {
    await ApplicationService.submitApplication({
      requestBody: { ...state.model }
    });
  };

  const handleSubmitSignedOpen = async () => {
    try {
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size === 0;

      if (hasNoErrors && currentScreen === WizardRoutesEnum.Summary) {
        setIsSubmitSignedOpen(true);
      }
    } catch (e) {
      if (e instanceof ApiError || e instanceof Error) {
        toast.warning(e.message);
      }
    }
  };

  const handleContinueClick = async () => {
    setIsContinuing(true);
    try {
      const isLastInsuredPersonScreen = currentScreen === WizardRoutesEnum.PrivateData;
      const index = ScreenOrder.indexOf(currentScreen) + 1;
      const nextPage = ScreenOrder[index];
      const validationResult = await validateNextPage(nextPage);
      const hasNoErrors = validationResult.size === 0;

      if (isLastInsuredPersonScreen && validationResult.size > 0) {
        setIsIncompletePersonDialogOpen(true);
        return;
      }

      if (hasNoErrors) {
        saveHe();

        // if current screen is summary screen then submit application
        if (currentScreen === WizardRoutesEnum.Summary) {
          setIsSubmitting(true);
          setIsSignatureDialogOpen(true);
          return;
        }

        next(state.model.id);
      }
    } catch (e) {
      if (e instanceof ApiError || e instanceof Error) {
        toast.warning(e.message);
      }
    } finally {
      setIsContinuing(false);
    }

    async function saveHe() {
      const saveResult = applicationStart ? await save(state.model) : { id: undefined };

      if (saveResult.id) {
        navigate(`${WizardRoutesEnum.Home}?id=${saveResult.id}`);
        updateApplicationId(saveResult.id);
      }
      return saveResult;
    }
  };

  const renderContinueIcon = () => {
    if (isSubmitting || isContinuing) {
      return (
        <span className="px-[3px]">
          <CircularProgress color="inherit" size={16} />
        </span>
      );
    }

    if (isOnLastPage) {
      if (isSubmitted) {
        return <CheckIcon />;
      }
      return null;
    }
    return <ArrowForwardOutlinedIcon />;
  };

  const renderDigitalizeIcon = () => {
    if (isSubmitSignedOpen) {
      return (
        <span className="px-[3px]">
          <CircularProgress color="inherit" size={16} />
        </span>
      );
    }

    return null;
  };

  const closeSignatureDialog = () => {
    setIsSignatureDialogOpen(false);
    setIsSubmitting(false);
  };

  const closeIncompletePersonDialog = () => {
    setIsIncompletePersonDialogOpen(false);
    setIsSubmitting(false);
  };

  return (
    <>
      {isSignatureDialogOpen && (
        <SignatureDialog
          isOpen={isSignatureDialogOpen}
          onClose={closeSignatureDialog}
          onManualSignatureClick={handleManualSignature}
          onDigitalSignatureClick={handleDigitalSignature}
        />
      )}
      {isIncompletePersonDialogOpen && (
        <IncompletePersonDialog
          isOpen={isIncompletePersonDialogOpen}
          onClose={closeIncompletePersonDialog}
        />
      )}
      {isServiceInternal && isOnLastPage && (
        <SubmitSigned
          onClose={() => {
            setIsSubmitSignedOpen(false);
          }}
          onBeforeUpload={handleSubmitSigned}
          holderName={holder.fullName}
          open={isSubmitSignedOpen}
          onDone={(id?: string) => {
            setIsSubmitted(true);
            setIsSignatureDialogOpen(false);
            navigate(`/360/antrag/${id}`);
          }}
          applicationId={state.model.id || ''}
        />
      )}
      {isServiceInternal && isOnLastPage && (
        <Button
          color="primary"
          variant="outlined"
          size="large"
          className="mr-s"
          onClick={handleSubmitSignedOpen}
          endIcon={renderDigitalizeIcon()}
          classes={{
            disabled: isSubmitted ? buttonClasses.disabled : 'bg-highlight-80 text-white-100'
          }}
          disabled={isSubmitSignedOpen}
        >
          {t('actions.uploadSignedApplication')}
        </Button>
      )}
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={handleContinueClick}
        endIcon={renderContinueIcon()}
        classes={{
          disabled: isSubmitted ? buttonClasses.disabled : 'bg-highlight-80 text-white-100'
        }}
        disabled={isProcessing || isSubmitted}
      >
        {(isSubmitting || isContinuing || isSigning) && isOnLastPage
          ? t('actions.nextStepSumProgress')
          : title}
      </Button>
    </>
  );
};
