import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router';

import { findLast } from 'lodash';

import { Action, FormState, reducers, useFormReducer } from 'lkh-portal-ui-library';
import {
  Application,
  ApplicationStatusEnum,
  Partner,
  PersonRoleEnum
} from 'models/extension-generated';

import { WizardRoutesEnum } from '../constants';
import { useApplicaitionPersistance } from '../hooks/useApplicationPersistance';
import { useApplicationValidation } from '../hooks/useApplicationValidation';
import { getPrivateDataScreenSchema } from '../hooks/useApplicationValidation/schemas';
import { useExtendedReducer } from '../hooks/useExtendedReducer';
import { useWizardNavigation } from '../hooks/useWizardNavigation';
import { createApplication } from '../utils';
import { useConfigContext } from 'contexts/ConfigContext';
import { useUser } from 'hooks/useUser';

/**
 * Context wrapper for everything related to the Application form for the Health Insurance
 */
export interface HealthInsuranceContextType {
  /**
   * Current Form state
   */
  state: FormState<Application>;

  /**
   * Dispatch function for all Form actions
   * NOTE: prefered way to trigger actions is via calling the reducer directly
   */
  dispatch: Dispatch<Action>;

  /**
   * Extended reducer API
   * Supports all native Form actions as well as any further extension actions
   */
  reducer: ReturnType<typeof useExtendedReducer>;

  /**
   * Currently selected partner unique identifier
   */
  partnerId: string;

  /**
   * Method to set the selected partner by ID
   */
  selectPartner: (id: string) => void;

  /**
   * Flag whether there is an active validation query in the backgroun
   */
  isValidating: boolean;

  /**
   * Whether there is loading event in progress.
   * This variable is true in following cases:
   *  - application load
   *  - initial configuration load
   */
  isLoading: boolean;
}

type HealthInsuranceContextProviderProps = PropsWithChildren;

export const HealthInsuranceContext = createContext<HealthInsuranceContextType | null>(null);

export function HealthInsuranceContextProvider({ children }: HealthInsuranceContextProviderProps) {
  const navigate = useNavigate();

  const { agentIds } = useUser();

  const initialState = {
    model: createApplication({
      agentId: agentIds?.[0] || undefined
    })
  };

  const [state, dispatch] = useFormReducer<Application>(initialState, reducers);
  const reducer = useExtendedReducer(dispatch);
  const { setDirty } = reducer;
  const [partnerId, _selectPartner] = useState<string>(state.model.partners[0].id || '');
  const { isValidating } = useApplicationValidation(state, reducer, partnerId);
  const { load } = useApplicaitionPersistance();
  const [isLoadingInitialState, setIsLoadingInitialState] = useState(true);
  const { currentScreen, goTo } = useWizardNavigation();
  const { isLoading: isConfigLoading } = useConfigContext();
  const isLoading = isConfigLoading || isLoadingInitialState;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idParam = urlParams.get('id');

    async function resolveInitialState() {
      setIsLoadingInitialState(true);
      if (idParam) {
        try {
          const fetchedApplication = await load(idParam);
          reducer.replaceModel(fetchedApplication);
          if (fetchedApplication.status !== ApplicationStatusEnum.OFFER_CREATED) {
            navigate(`/360/antrag/${fetchedApplication.id}`);
            return;
          }
          // the partners are already sorted by order but need to select insured person (and no holder, ...)
          const partnerToSelect = findLast<Partner>(fetchedApplication.partners, ({ roles }) =>
            roles.includes(PersonRoleEnum.INSURED_PERSON)
          );

          if (partnerToSelect?.id) {
            _selectPartner(partnerToSelect.id);
          }
        } catch (e) {
          goTo(WizardRoutesEnum.Tariffs);
        }
      } else if (currentScreen !== WizardRoutesEnum.Tariffs) {
        goTo(WizardRoutesEnum.Tariffs);
      }
      setIsLoadingInitialState(false);
    }

    resolveInitialState();
  }, []);

  const selectPartner = (id: string) => {
    _selectPartner(id);
    goTo(WizardRoutesEnum.Tariffs);

    const privateDataScreenFields = getPrivateDataScreenSchema(state.model, partnerId);

    privateDataScreenFields.forEach(setDirty);
  };

  const ctx = useMemo(() => {
    return {
      state,
      dispatch,
      reducer,
      partnerId,
      selectPartner,
      isValidating,
      isLoading
    };
  }, [state, dispatch, reducer, partnerId, selectPartner, isValidating, isLoading]);

  return <HealthInsuranceContext.Provider value={ctx}>{children}</HealthInsuranceContext.Provider>;
}

export function useHealthInsuranceContext(): HealthInsuranceContextType {
  const healthInsuranceContext = useContext(HealthInsuranceContext);

  if (!healthInsuranceContext) {
    throw new Error(
      'To use "useHealthInsuranceContext" some of the parent components must be in <HealthInsuranceContextProvider>'
    );
  }

  return healthInsuranceContext;
}
