import { useState } from 'react';

import flatten from 'lodash/flatten';

import { FormState, parseValidationResults, ValidationResult } from 'lkh-portal-ui-library';
import { RecursiveKeyOf } from 'models';
import { ApiError, Application, ApplicationService } from 'models/extension-generated';

import { useExtendedReducer } from '../useExtendedReducer';
import { useWizardNavigation } from '../useWizardNavigation';
import { getJoinedValidationByRoute, getMinimalRequiredFields } from './schemas';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';

export type UseExtendedReducerType = ReturnType<typeof useExtendedReducer>;

/**
 * Hook for auto triggering of the async validation
 */
export const useApplicationValidation = (
  state: FormState<Application>,
  reducer: UseExtendedReducerType,
  partnerId?: string
) => {
  const [isValidating, setIsValidating] = useState(false);
  const { ScreenOrder } = useWizardNavigation();

  const { setValidationResults, setDirty, addValidationResults } = reducer;

  /**
   * Calls BE service to get the validation results
   */
  async function validateAsync(
    validatedFields: Array<RecursiveKeyOf<Application>>,
    keepPreviousErrors: boolean
  ): Promise<Map<string, ValidationResult>> {
    setIsValidating(true);

    try {
      await ApplicationService.validateApplication({ requestBody: state.model });
      const emptyValidationResult = new Map<string, ValidationResult>();

      setValidationResults({ results: emptyValidationResult });

      return emptyValidationResult;
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.body.errors.length) {
          const errors = error.body.errors;
          const results = parseValidationResults(errors, validatedFields, state);
          if (keepPreviousErrors) {
            addValidationResults({ results });
          } else {
            setValidationResults({ results });
          }
          return results;
        }
      }
    } finally {
      setIsValidating(false);
    }
    return new Map<string, ValidationResult>();
  }

  /*
   * Validate minimal viable partner that can be stored
   */
  async function validatePages({
    pages,
    currentPartnerId,
    completeValidation
  }: {
    pages: Array<WizardRoutesEnum>;
    currentPartnerId?: string;
    completeValidation?: boolean;
  }): Promise<Map<string, ValidationResult>> {
    const fieldsToValidate = flatten(
      pages.map((page) => {
        return getJoinedValidationByRoute(page, state.model, currentPartnerId, completeValidation);
      })
    );

    fieldsToValidate.forEach(setDirty);

    return validateAsync(fieldsToValidate, false);
  }

  async function validateMinimalPartner(partnerId: string): Promise<Map<string, ValidationResult>> {
    if (!partnerId) return new Map<string, ValidationResult>();
    const minimalRequirementFields = getMinimalRequiredFields(partnerId);
    minimalRequirementFields.forEach(setDirty);

    return validateAsync(minimalRequirementFields, true);
  }

  async function validateNextPage(nextPage: WizardRoutesEnum) {
    const indexOfTargetPage = ScreenOrder.indexOf(nextPage);
    const priorPages = ScreenOrder.slice(0, indexOfTargetPage);
    const shouldValidateAllPartners = nextPage === WizardRoutesEnum.InsuranceHolder;

    const validation = await validatePages({
      pages: priorPages,
      currentPartnerId: partnerId,
      completeValidation: shouldValidateAllPartners
    });

    return validation;
  }

  return {
    isValidating,
    validatePages,
    validateMinimalPartner,
    validateNextPage
  };
};
