import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { PartnerCalculationResult } from 'models/extension-generated';

import {
  BRE_DOCUMENT_2024,
  BRE_DOCUMENT_2025,
  DEFAULT_BRE_DOCUMENT
} from 'pages/PrivateHealthInsurance/constants';
import { downloadFormByReferenceId } from 'utilities/fetchDocument';
import { formatPrice } from 'utilities/string';

type PriceSummaryProps = {
  isLoading: boolean;
  calculation?: PartnerCalculationResult;
  applicationStart: string;
  showBRE: boolean;
  isFinalYearForBre: boolean;
};

export const PriceSummary = ({
  isLoading,
  calculation,
  showBRE,
  applicationStart,
  isFinalYearForBre
}: PriceSummaryProps) => {
  const { t } = useTranslation('wizardOffer');
  const insuranceStartYear = moment(new Date()).year();
  const handleDetailsDownload = async () => {
    const formId = isFinalYearForBre ? BRE_DOCUMENT_2024 : DEFAULT_BRE_DOCUMENT;
    const doc = await downloadFormByReferenceId({ id: formId, date: applicationStart });
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    window.open(fileURL, '_blank', 'noreferrer');
  };

  const handleDownloadRefundDocs = async () => {
    const formId = isFinalYearForBre ? BRE_DOCUMENT_2024 : BRE_DOCUMENT_2025;
    const doc = await downloadFormByReferenceId({ id: formId, date: applicationStart });
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    window.open(fileURL, '_blank', 'noreferrer');
  };

  return (
    <>
      {showBRE && (
        <div className="bg-surface-80 rounded-[8px] px-[16px] py-[24px] text-text-80">
          {/* Cost conscious price */}

          {isFinalYearForBre && (
            <>
              <div className="flex justify-between pb-[24px]">
                <div className="flex-1 pr-[100px]">
                  <Typography variant="bodySMRegular">
                    <Trans
                      i18nKey="costConsciousnessPrice"
                      ns="wizardOffer"
                      components={{ bold: <strong /> }}
                    />
                    <Typography
                      component="a"
                      variant="bodySMRegularLink"
                      onClick={handleDetailsDownload}
                      className="text-primary-80 cursor-pointer"
                    >
                      ({t('wizardTariffs:utils.seeDetails')})
                    </Typography>
                    :
                  </Typography>
                </div>
                <div className="pr-[42px]">
                  {isLoading ? (
                    <Typography variant="bodySMBold" data-testid="price">
                      -,--
                    </Typography>
                  ) : (
                    <Typography variant="bodySMBold" data-testid="price">
                      {formatPrice(calculation?.calculationMonthly?.costConsciousAmount)}
                    </Typography>
                  )}
                </div>
              </div>

              <div className="flex-1 pr-[100px] pb-[24px]">
                <Typography variant="bodySMRegular">
                  <Trans
                    i18nKey="lastYearAvailabilityWarning"
                    ns="wizardOffer"
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              </div>
            </>
          )}

          {/* No benefits */}
          <div className="flex justify-between">
            <div className="flex-1 pr-[100px]">
              <Typography variant="bodySMRegular">
                <Trans
                  i18nKey="noBenefitsPrice"
                  ns="wizardOffer"
                  components={{ bold: <strong /> }}
                />
              </Typography>
            </div>
            <div className="pr-[42px]">
              {isLoading ? (
                <Typography variant="bodySMBold" data-testid="price">
                  -,--
                </Typography>
              ) : (
                <Typography variant="bodySMBold">
                  {formatPrice(calculation?.calculationMonthly?.maxTaxDeductibleAmount)}
                </Typography>
              )}
            </div>
          </div>

          <div className="flex justify-between pt-[24px]">
            <div className="flex-1 pr-[100px]">
              <Typography variant="bodySMRegular">
                <Trans
                  i18nKey="discountedPrice2025"
                  ns="wizardOffer"
                  values={{ insuranceStartYear }}
                  components={{ bold: <strong /> }}
                />
              </Typography>
              <Typography
                component="a"
                variant="bodySMRegularLink"
                onClick={handleDownloadRefundDocs}
                className="text-primary-80 cursor-pointer"
              >
                ({t('wizardTariffs:utils.seeDetails')})
              </Typography>
              <Typography variant="bodySMRegular">:</Typography>
            </div>
            <div className="pr-[42px]">
              {isLoading ? (
                <Typography variant="bodySMBold" data-testid="price">
                  -,--
                </Typography>
              ) : (
                <Typography variant="bodySMBold">
                  {formatPrice(calculation?.calculationMonthly?.maximumRefundLkhBenefit)}
                </Typography>
              )}
            </div>
          </div>

          <Divider className="my-[24px]" />
          <Typography variant="bodySMRegular">{t('wizardOffer:premiumRefundsNote')}</Typography>
        </div>
      )}
    </>
  );
};
