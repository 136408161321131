import { useState } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

type TariffDescriptionProps = {
  description: string;
};

export const TariffDescription = ({ description }: TariffDescriptionProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'expandBtn' });
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const rawDescription = description.replace(/\\n/g, '\n');

  const pattern = /\(f\)(.*?)\(\/f\)/g;
  const tariffName = rawDescription
    ?.match(pattern)
    ?.map((match) => match.replace(/\(f\)|\(\/f\)/g, ''));
  const tariffDescription = rawDescription?.replace(pattern, '').trim();
  const title = get(tariffName, '0', '');

  return (
    <Stack direction="column" spacing={1}>
      <Typography className="text-xs font-bold mb-[12px]">{title}</Typography>
      <Collapse in={expanded} collapsedSize={60}>
        <div
          className={classNames('text-xs font-normal', {
            'h-[50vh]': expanded
          })}
        >
          {tariffDescription}
        </div>
      </Collapse>
      <div>{expanded ? '' : <div className="text-xs font-normal">...</div>}</div>
      <div className="flex align-middle">
        {expanded ? null : (
          <>
            <Link
              component="button"
              onClick={toggleExpanded}
              disabled={expanded}
              sx={{
                color: colors.white[100],
                textDecorationColor: colors.white[100],
                lineHeight: '16px',
                fontSize: '12px',
                fontWeight: 400,
                '&:hover': {
                  color: colors.white[100]
                }
              }}
            >
              {t('showMore')}
            </Link>
            <ExpandMoreIcon />
          </>
        )}
      </div>
    </Stack>
  );
};
