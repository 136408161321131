/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentResponse } from '../models/AgentResponse';
import type { AgentsResponse } from '../models/AgentsResponse';
import type { Criteria } from '../models/Criteria';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServiceCenterService {

    /**
     * Search for an agent
     * @returns AgentResponse Successfully retrieved data
     * @throws ApiError
     */
    public static getAgentByCriteria({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<AgentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/serviceCenter/agent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found - The agent was not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Search for an agent in keycloak
     * @returns AgentsResponse Successfully retrieved data
     * @throws ApiError
     */
    public static getAgentsByCriteria({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<AgentsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/serviceCenter/agents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found - The agent was not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

}
