/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Criteria } from '../models/Criteria';
import type { DocumentMetadata } from '../models/DocumentMetadata';
import type { IdResponse } from '../models/IdResponse';
import type { PoliciesResponse } from '../models/PoliciesResponse';
import type { Policy } from '../models/Policy';
import type { PolicyDocumentsResponse } from '../models/PolicyDocumentsResponse';
import type { PolicyNote } from '../models/PolicyNote';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PolicyService {

    /**
     * Get policies list with respect to filters, sorting and pagination query parameters
     * @returns PoliciesResponse Successfully retrieved data
     * @throws ApiError
     */
    public static getPolicies({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<PoliciesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get single policy by id
     * @returns Policy Successfully retrieved
     * @throws ApiError
     */
    public static getPolicyById({
        policyId,
        revisionId,
    }: {
        /**
         * Policy unique identifier
         */
        policyId: number,
        /**
         * Revision unique identifier
         */
        revisionId?: number,
    }): CancelablePromise<Policy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/policy/{policyId}',
            path: {
                'policyId': policyId,
            },
            query: {
                'revisionId': revisionId,
            },
            errors: {
                404: `Not found - The policy was not found`,
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

    /**
     * Upload document for policy
     * @returns any Document successfully uploaded
     * @throws ApiError
     */
    public static uploadDocumentForPolicy({
        policyId,
        formData,
    }: {
        /**
         * Policy unique identifier
         */
        policyId: number,
        formData: {
            files: Array<Blob>;
            metadata: Array<DocumentMetadata>;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/{policyId}/uploadDocument',
            path: {
                'policyId': policyId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Upload not successful`,
                404: `Not found - The policy was not found`,
                417: `Virus detected in file`,
                422: `File is encrypted or unsupported file type`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Upload notes for policies
     * @returns any Notes successfully uploaded
     * @throws ApiError
     */
    public static uploadNotesForPolicies({
        formData,
    }: {
        formData: {
            files: Array<Blob>;
            username: string;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/policy/notes',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Upload not successful`,
                404: `Not found - The policy was not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Save note for the policy
     * @returns IdResponse Successfully retrieved data
     * @throws ApiError
     */
    public static savePolicyNote({
        policyId,
        requestBody,
    }: {
        /**
         * Policy unique identifier
         */
        policyId: number,
        requestBody?: PolicyNote,
    }): CancelablePromise<IdResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/{policyId}/note',
            path: {
                'policyId': policyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request`,
                404: `Policy not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Delete policy note
     * @returns any Successfully deleted
     * @throws ApiError
     */
    public static deletePolicyNote({
        policyId,
        noteId,
    }: {
        /**
         * Policy unique identifier
         */
        policyId: number,
        /**
         * Note unique identifier
         */
        noteId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/policy/{policyId}/note/{noteId}',
            path: {
                'policyId': policyId,
                'noteId': noteId,
            },
            errors: {
                400: `Invalid request`,
                404: `Policy or Note not found`,
                500: `Internal Server Error - unexpected error occurred`,
            },
        });
    }

    /**
     * Get policy documents
     * @returns PolicyDocumentsResponse Successfully retrieved data
     * @throws ApiError
     */
    public static getPolicyDocuments({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<PolicyDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/documents',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get csv export
     * @returns any Successfully retrieved data
     * @throws ApiError
     */
    public static getCsvExport({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/csv/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get provision csv export
     * @returns any Successfully retrieved data
     * @throws ApiError
     */
    public static getProvisionCsvExport({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/csv/export/provision',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get reminders csv export
     * @returns any Successfully retrieved data
     * @throws ApiError
     */
    public static getRemindersCsvExport({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/policy/csv/export/reminders',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download document for policy
     * @returns string Document successfully downloaded
     * @throws ApiError
     */
    public static downloadDocumentForPolicy({
        policyId,
        docId,
    }: {
        /**
         * Policy unique identifier
         */
        policyId: number,
        /**
         * doc unique identifier
         */
        docId: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/policy/{policyId}/document/{docId}',
            path: {
                'policyId': policyId,
                'docId': docId,
            },
            errors: {
                400: `Bad Request. DPPGNR has wrong Format or doesnt exist.`,
                401: `Unauthorized. Missing Authentification or wrong IP.`,
                403: `Forbidden. None HTTPS Protocol or User is not allowed.`,
                500: `Internal Server Error. Configuration- or Program-Error.`,
            },
        });
    }

    /**
     * Get all tariffs in agents policies
     * @returns string Successfully retrieved
     * @throws ApiError
     */
    public static getPoliciesTariffs(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/policy/tariffs',
            errors: {
                404: `Not found - The tariffs were not found`,
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

}
