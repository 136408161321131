import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField, RealmRole } from 'models';
import { ApplicationService } from 'models/extension-generated';

import { WizardRoutesEnum } from '../../constants';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useApplicaitionPersistance } from '../../hooks/useApplicationPersistance';
import { AgentPickerDialog } from '../AgentPickerDialog';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { ActionMenu } from 'pages/Contract360Page/components/ActionMenu';

type OrderOptionsMenuProps = {
  saveDisabled: boolean;
};

export const OrderOptionsMenu = ({ saveDisabled: disabled }: OrderOptionsMenuProps) => {
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const { state, reducer, partnerId } = useHealthInsuranceContext();
  const { save, drop } = useApplicaitionPersistance();
  const { getConfig } = useApplicationHelpers(state.model);
  const { orderOptionsEnabled } = getConfig(partnerId);
  const { t } = useTranslation('wizardPrivateHealthInsurance');

  const isServiceInternal = keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isAgentPickerDialogOpen, setIsAgentPickerDialogOpen] = useState(false);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handleSave = async () => {
    const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
    if (!applicationStart) {
      toast.warning(t<string>('common:toastMessages.missingApplicationStartDate'), {
        delay: 500
      });
    } else {
      const { id } = await save(state.model);
      if (!state.model.id) {
        reducer.updateValue({
          key: 'id',
          value: id
        });
        navigate(`${WizardRoutesEnum.Home}?id=${id}`);
      }
      toast.success(t<string>('orderOptions.menu.saveConfirm'));
    }
  };

  const handleDuplicate = async () => {
    if (state.model.id) {
      const applicationCopy = await ApplicationService.copyApplication({
        id: state.model.id
      });
      const application = await ApplicationService.createApplication({
        requestBody: applicationCopy
      });

      toast.success(t<string>('orderOptions.menu.duplicateConfirm'));
      window.open(`${WizardRoutesEnum.Home}?id=${application.id}`);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const modelId = state.model.id;
      if (modelId) {
        await drop(modelId);
      }
    } finally {
      toast.success(t<string>('orderOptions.menu.deleteConfirm'));
      setDialogOpen(false);
      setIsDeleting(false);
      navigate('/');
    }
  };

  const handleOrderReassignment = async (data: { agentId: string }) => {
    const applicationStart = formModelGet(state.model, applicationField('applicationStart'));
    const agentIdPath = applicationField('agentId');
    if (!applicationStart) {
      toast.warning(t<string>('common:toastMessages.missingApplicationStartDate'), {
        delay: 500
      });
    } else {
      await save({ ...state.model, agentId: data.agentId });
      reducer.updateValue({
        key: agentIdPath,
        value: data.agentId
      });
      toast.success(
        t<string>('wizardPrivateHealthInsurance:navigation.agentPicker.reassign.successToast')
      );
    }
  };

  return (
    <>
      <AgentPickerDialog
        translations={{
          title: t('wizardPrivateHealthInsurance:navigation.agentPicker.reassign.title'),
          description: t('wizardPrivateHealthInsurance:navigation.agentPicker.reassign.description')
        }}
        isDialogOpen={isAgentPickerDialogOpen}
        handleAgentSelection={handleOrderReassignment}
        onDialogClose={() => setIsAgentPickerDialogOpen(false)}
      />
      <ActionMenu
        isLight
        items={
          <div>
            <MenuItem disabled={!orderOptionsEnabled} onClick={() => handleSave()}>
              <ListItemIcon>
                <SaveOutlinedIcon className="fill-text-80" />
              </ListItemIcon>
              <ListItemText className="text-text-80">{t('orderOptions.menu.save')}</ListItemText>
            </MenuItem>

            <MenuItem disabled={!orderOptionsEnabled} onClick={() => handleDuplicate()}>
              <ListItemIcon>
                <ContentCopyOutlinedIcon className="fill-text-80" />
              </ListItemIcon>
              <ListItemText className="text-text-80">
                {t('orderOptions.menu.duplicate')}
              </ListItemText>
            </MenuItem>
            {isServiceInternal && (
              <MenuItem
                disabled={!orderOptionsEnabled}
                onClick={() => {
                  setIsAgentPickerDialogOpen(true);
                }}
              >
                <ListItemIcon>
                  <HowToRegOutlinedIcon className="fill-text-80" />
                </ListItemIcon>
                <ListItemText className="text-text-80">
                  {t('dashboard:actions.reassign')}
                </ListItemText>
              </MenuItem>
            )}
            <Divider key="table-action-menu-divider" className="m-[0px]" />
            <MenuItem
              disabled={!orderOptionsEnabled}
              key="table-action-menu-delete-time"
              className="hover:bg-danger-10"
              onClick={() => handleOpenDialog()}
            >
              <ListItemIcon>
                <DeleteForeverOutlinedIcon className="fill-danger-60" />
              </ListItemIcon>
              <ListItemText className="text-danger-60">
                {t('orderOptions.menu.delete')}
              </ListItemText>
            </MenuItem>
          </div>
        }
      />

      {isDialogOpen && (
        <Dialog
          disableScrollLock
          fullWidth
          maxWidth="sm"
          open={isDialogOpen}
          onClose={handleCloseDialog}
        >
          <DialogContent className="py-[24px] px-[32px]">
            <div className="flex space-x-[16px]">
              <div>
                <ErrorIcon className="text-danger-60" />
              </div>
              <div>
                <Typography className="flex text-text-100" variant="bodyLGBold">
                  {t('orderOptions.deleteConfirmation.title')}
                </Typography>
                <DialogContentText>
                  <Typography className="flex text-text-80 mt-[4px]" variant="bodyMDRegular">
                    {t('orderOptions.deleteConfirmation.message')}
                  </Typography>
                </DialogContentText>
              </div>
            </div>
            <div className="flex gap-[16px] justify-end mt-[32px]">
              <Button size="large" onClick={handleCloseDialog}>
                {t('orderOptions.deleteConfirmation.abort')}
              </Button>
              <Button
                size="large"
                variant="contained"
                color="error"
                startIcon={
                  isDeleting ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    <DeleteForeverOutlinedIcon />
                  )
                }
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting
                  ? t('orderOptions.deleteConfirmation.wait')
                  : t('orderOptions.deleteConfirmation.delete')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
